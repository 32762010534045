import { DateFormater } from '@/_helpers/funciones';
import {saveAs} from "file-saver";
import Excel from "exceljs/dist/es5/exceljs.browser";
import { mapState } from 'vuex';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';

function data() {
  return {
    dataBuque:{
      CountryName:'',
      DatePlanning:'',
      VesselFlagRoute:'',
    },
  }
}

function numeroLetra(valor) {
    const abc = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"];

    return abc[valor - 1];
}

async function getIdleTime(items) {
    if (items[0].ExcelJson.length>0){  
        let bandera = false;
        let _this = this.$i18n.locale;  

        if(items.length>2){
            
            //PRIMERA HOJA
            let hojaInicio = [];
            items[0].ExcelJson.forEach(x => {
              hojaInicio.push({
                DESCRIPCION_EN: x.DelayCustomNameEn,
                DESCRIPCION_ES: x.DelayCustomNameEs,
                IDLE_TIME: x.TotalTime,
                RESPONSABLE_EN: x.ResponsibleNameEn,
                RESPONSABLE_ES: x.ResponsibleNameEs,
              });
              bandera = true;
            });
  
            //ULTIMA HOJA 
            let posFinalSheet= items.length-1;
            let hojaFinal = [];
            if(this.bulkCarrier == 1){ 
                items[posFinalSheet].ExcelJson.forEach(x => {
                    hojaFinal.push({ 
                        DESCRIPCION_EN: x.DelayCustomNameEn ? x.DelayCustomNameEn : '',
                        DESCRIPCION_ES: x.DelayCustomNameEs ? x.DelayCustomNameEs : '',
                        TIEMPO: x.TotalTime ? x.TotalTime : '', 
                        RESPONSABLE_EN: x.ResponsibleNameEn ? x.ResponsibleNameEn : '',
                        RESPONSABLE_ES: x.ResponsibleNameEs ? x.ResponsibleNameEs : '',
                        INICIO_OPERACIONES: x.BeginningOperations ? x.BeginningOperations : '',
                        HORA_INICIO: x.BeginningOperationsHour ? x.BeginningOperationsHour : '',
                        FIN_OPERACIONES: x.EndOperations ? x.EndOperations : '',
                        HORA_FINAL: x.EndOperationsHour ? x.EndOperationsHour : '',
                        TIEMPO_OPERACIONES: x.OperationsTime ? x.OperationsTime : '',
                        TIEMPO_PERDIDO: x.IdleTime ? x.IdleTime : '',
                        TIEMPO_NETO: x.NetTime ? x.NetTime : '',
                    });
                bandera = true;
                });
            }if(this.bulkCarrier == 2){
                items[posFinalSheet].ExcelJson.forEach(x => {
                    hojaFinal.push({ 
                        DESCRIPCION_EN: x.DelayCustomNameEn ? x.DelayCustomNameEn : '',
                        DESCRIPCION_ES: x.DelayCustomNameEs ? x.DelayCustomNameEs : '',
                        TIEMPO: x.TotalTime ? x.TotalTime : '', 
                        RESPONSABLE_EN: x.ResponsibleNameEn ? x.ResponsibleNameEn : '',
                        RESPONSABLE_ES: x.ResponsibleNameEs ? x.ResponsibleNameEs : '',
                        INICIO_OPERACIONES: x.BeginningOperations ? x.BeginningOperations : '',
                        HORA_INICIO: x.BeginningOperationsHour ? x.BeginningOperationsHour : '',
                        FIN_OPERACIONES: x.EndOperations ? x.EndOperations : '',
                        HORA_FINAL: x.EndOperationsHour ? x.EndOperationsHour : '',
                        TIEMPO_OPERACIONES: x.OperationsTime ? x.OperationsTime : '',
                        TIEMPO_PERDIDO: x.IdleTime ? x.IdleTime : '',
                        TIEMPO_NETO: x.NetTime ? x.NetTime : '',
                        WEIGHT: x.TotalNetWeight ? x.TotalNetWeight : 0,
                    });
                bandera = true;
                });
            }
            //HOJAS DINAMICAS
            let longitudItems=items.length-1;
            let l;
            let hojaDinamica = [];
 
            for(l=1; l<longitudItems; l++){    
                if(this.bulkCarrier == 1){ 
                    let hoja= [];
                    items[l].ExcelJson.forEach(x => {
                    hoja.push({
                        CODIGO: x.CustomCode ? x.CustomCode : '',
                        DESCRIPCION_EN: x.DelayCustomNameEn ? x.DelayCustomNameEn : '',
                        DESCRIPCION_ES: x.DelayCustomNameEs ? x.DelayCustomNameEs : '',
                        TAPA: x.VesselHoldName ? x.VesselHoldName : '',
                        TAPA_NOMBRE: x.HatchCoverName ? x.HatchCoverName : '',
                        TAPA_UBICACION: x.HatchCoverLocName ? x.HatchCoverLocName : '',
                        CAJAS_PIÑA: x.Quantity ? x.Quantity : '',
                        CODIGO_TDR: x.TdrCode ? x.TdrCode : '',
                        BAYSLOT: x.BaySlot ? x.BaySlot : '',
                        FECHA_TRANSACCION: x.TransactionDate ? DateFormater.formatDateTimeWithSlash(x.TransactionDate) : '',
                        DESDE: x.FgDelay ? (x.TransactionBegin ? DateFormater.formatDateTimeWithSlash(x.TransactionBegin) : '')  : '', 
                        HASTA: x.FgDelay ? (x.TransactionFinish ? DateFormater.formatDateTimeWithSlash(x.TransactionFinish) : '') : '',
                        TIEMPO_PERDIDO: (x.FgDelay && x.TransactionDate == null) ? ( x.TotalTime ? x.TotalTime : '')  : '',
                        RESPONSABLE_EN: x.ResponsibleNameEn ? x.ResponsibleNameEn : '',
                        RESPONSABLE_ES: x.ResponsibleNameEs ? x.ResponsibleNameEs : '',
                        TURNO_INICIO: x.TransactionBeginTurnName ? x.TransactionBeginTurnName : '',
                        TURNO_FINAL: x.TransactionFinishTurnName ? x.TransactionFinishTurnName : '',
                        OBSERVATION: x.Observation ? x.Observation : '',
                    });
                    bandera = true;
                    }); 
                    
                    hojaDinamica.push({
                    hoja, 
                    });
                }
                if(this.bulkCarrier == 2){ 
                    let hoja= [];
                    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
                    items[l].ExcelJson.forEach(x => {
                        if (this.FgGeneralCargo) {
                            let PackagingJson = x.PackagingJson ?? [];
                            hoja.push({
                                CODIGO: x.CustomCode ? x.CustomCode : '',
                                DESCRIPCION_EN: x.DelayCustomNameEn ? x.DelayCustomNameEn : '',
                                DESCRIPCION_ES: x.DelayCustomNameEs ? x.DelayCustomNameEs : '',
                                TpCargoName: x['TpCargoName'+_lang] ?? '',
                                VesselUbicationName: x['VesselUbicationName'+_lang] ?? '',
                                TAPA: x.VesselHoldName ? x.VesselHoldName : '',
                                TAPA_NOMBRE: x.HatchCoverName ? x.HatchCoverName : '',
                                TAPA_UBICACION: x.HatchCoverLocName ? x.HatchCoverLocName : '',
                                PackagingName: PackagingJson.map(Packaging => {
                                    return `${Packaging['PackagingName'+_lang] ?? ''}: ${Packaging?.Quantity ?? 0}`
                                }).join(', '),
                                TotalQuantity: x.TotalQuantity ?? 0,
                                MACHINE: x.MachineName ? x.MachineName : '',
                                DESDE: !x.FgDelay ? (x.TransactionBegin ? DateFormater.formatDateTimeWithSlash(x.TransactionBegin) : '')  : '', 
                                HASTA: !x.FgDelay ? (x.TransactionFinish ? DateFormater.formatDateTimeWithSlash(x.TransactionFinish) : '') : '',
                                FECHA_TRANSACCION: x.TransactionDate ? DateFormater.formatDateTimeWithSlash(x.TransactionDate) : '',
                                DESDEDELAY: x.FgDelay ? (x.TransactionBegin ? DateFormater.formatDateTimeWithSlash(x.TransactionBegin) : '')  : '', 
                                HASTADELAY: x.FgDelay ? (x.TransactionFinish ? DateFormater.formatDateTimeWithSlash(x.TransactionFinish) : '') : '',
                                TIEMPO_PERDIDO: (x.FgDelay && x.TransactionDate == null) ? ( x.TotalTime ? x.TotalTime : '')  : '',
                                RESPONSABLE_EN: x.ResponsibleNameEn ? x.ResponsibleNameEn : '',
                                RESPONSABLE_ES: x.ResponsibleNameEs ? x.ResponsibleNameEs : '',
                                TURNO_INICIO: x.TransactionBeginTurnName ? x.TransactionBeginTurnName : '',
                                TURNO_FINAL: x.TransactionFinishTurnName ? x.TransactionFinishTurnName : '',
                                CORTE: x.VisitLapseCode ? x.VisitLapseCode : '',
                                OBSERVATION: x.Observation ? x.Observation : '',
                            });
                        }else{
                            hoja.push({
                                CODIGO: x.CustomCode ? x.CustomCode : '',
                                DESCRIPCION_EN: x.DelayCustomNameEn ? x.DelayCustomNameEn : '',
                                DESCRIPCION_ES: x.DelayCustomNameEs ? x.DelayCustomNameEs : '',
                                TAPA: x.VesselHoldName ? x.VesselHoldName : '',
                                WEIGHT: x.NetWeight ? x.NetWeight : 0,
                                TAPA_NOMBRE: x.HatchCoverName ? x.HatchCoverName : '',
                                TAPA_UBICACION: x.HatchCoverLocName ? x.HatchCoverLocName : '',
                                MACHINE: x.MachineName ? x.MachineName : '',
                                DESDE: !x.FgDelay ? (x.TransactionBegin ? DateFormater.formatDateTimeWithSlash(x.TransactionBegin) : '')  : '', 
                                HASTA: !x.FgDelay ? (x.TransactionFinish ? DateFormater.formatDateTimeWithSlash(x.TransactionFinish) : '') : '',
                                FECHA_TRANSACCION: x.TransactionDate ? DateFormater.formatDateTimeWithSlash(x.TransactionDate) : '',
                                DESDEDELAY: x.FgDelay ? (x.TransactionBegin ? DateFormater.formatDateTimeWithSlash(x.TransactionBegin) : '')  : '', 
                                HASTADELAY: x.FgDelay ? (x.TransactionFinish ? DateFormater.formatDateTimeWithSlash(x.TransactionFinish) : '') : '',
                                TIEMPO_PERDIDO: (x.FgDelay && x.TransactionDate == null) ? ( x.TotalTime ? x.TotalTime : '')  : '',
                                RESPONSABLE_EN: x.ResponsibleNameEn ? x.ResponsibleNameEn : '',
                                RESPONSABLE_ES: x.ResponsibleNameEs ? x.ResponsibleNameEs : '',
                                TURNO_INICIO: x.TransactionBeginTurnName ? x.TransactionBeginTurnName : '',
                                TURNO_FINAL: x.TransactionFinishTurnName ? x.TransactionFinishTurnName : '',
                                CORTE: x.VisitLapseCode ? x.VisitLapseCode : '',
                                OBSERVATION: x.Observation ? x.Observation : '',
                            });
                        }
                        bandera = true;
                    }); 
                    
                    hojaDinamica.push({
                    hoja, 
                    });
                }
            }
  
            let rowData = [
            {
              hojaInicio: hojaInicio,
              hojaFinal: hojaFinal,
              hojaDinamica: hojaDinamica, 
            }];
  
            const workbook = new Excel.Workbook();
            const documento = "IDLE TIME";
          
            //PRIMERA HOJA
            var titulo= items[0].ExcelTabName;
            const worksheet = workbook.addWorksheet(titulo);
           
            //INFO BUQUE
            let tituloExcel = "";
          
            tituloExcel = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName+", "+this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - Viaje: "+this.itinerarySelected.Voyage;
          
            let y = 0;
            let letra = 11;
            let columnsDefs = [];
            let keyColumns = [];
            let bandera = true;
          
            keyColumns = Object.keys(rowData[0].hojaInicio[0]);
            columnsDefs = [
                this.$t('label.description')+' (EN)',
                this.$t('label.description')+' (ES)',
                'IDLE TIME',
                this.$t('label.responsible')+' (EN)',
                this.$t('label.responsible')+' (ES)',
            ];
            y = 15;
            bandera = false;
          
            if (y < 10) letra = 8;
            worksheet.mergeCells('A1:A2');
            worksheet.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
          
            let response1 = await this.$http.getFile('base64', {
              path: this.user.LogoRoute.replace('Public/', '', null, 'i')
            });
          
            let divisiones1 = this.user.LogoRoute.split(".");
            let myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
            let imageId = workbook.addImage({
              base64: myBase64Image,
              extension: divisiones1[1],
            });
            worksheet.addImage(imageId, 'A1:A2');
          
            let rowIndex1 = worksheet.getRow(1);
            rowIndex1.height = 100;
          
            worksheet.mergeCells("B1:E1");
            worksheet.mergeCells("B2:E2");
            const customCellCompanyBranch = worksheet.getCell("B1");

            let row = worksheet.getRow(1);
            row.height = 30;
            customCellCompanyBranch.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellCompanyBranch.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranch.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranch.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
            customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

            const row2 = worksheet.getRow(2);    
            row2.height = 50;
            const customCell = worksheet.getCell("B2");
            customCell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };      
          
            customCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                },
            }
          
            customCell.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
                 
            customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
            customCell.value = tituloExcel;
          
            let hoy = new Date();
          
            worksheet.mergeCells("A3:C3");
            let customCell2 = worksheet.getCell("A3");
            customCell2.height = 50;
            customCell2.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell2.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCell2.alignment = { vertical: 'middle', horizontal: 'center' }
            customCell2.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy);//hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
           
            worksheet.mergeCells("D3:E3");
            let customCell4 = worksheet.getCell("D3");
            customCell4.height = 50;
            customCell4.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell4.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCell4.alignment = { vertical: 'middle', horizontal: 'center' }
            customCell4.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
          
            worksheet.mergeCells("A4:"+numeroLetra(y)+"4");
            let customCell5 = worksheet.getCell("A4");
            customCell5.height = 50;
            customCell5.alignment = { vertical: 'middle', horizontal: 'center' }
          
            var headerRow = worksheet.addRow();
            headerRow.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };
            
            let i = 0;
            columnsDefs.map(function (data) {
              let valor = bandera ? data.headerName.length : data.length;

              let long = 10;
              if ((valor >= 5) && (valor <= 12)) {
                  long = 20;
              } else if ((valor >= 11) && (valor <= 18)) {
                  long = 25;
              } else if ((valor >= 19) && (valor <= 26)) {
                  long = 30;
              } else if (valor >= 27) {
                  long = 40;
              }
          
              worksheet.getColumn(i + 1).width = long;
              let cell = headerRow.getCell(i + 1);
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
              }
              cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
              }
              cell.value = data.replace("_"," ");
              cell.alignment = { vertical: 'middle', horizontal: 'center' }
              i++;
            })
          
            rowData[0].hojaInicio.map(async function (data) {
              let dataRow = worksheet.addRow();
              let indice = 0;
              let bandera=false;
              keyColumns.map(function (data2) {
                    let cell = dataRow.getCell(indice + 1);

                    cell.value = data[data2];
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
                    indice++;
              })
            })
          
          ////////////////////////////////////////////////////////////////////////////////////////////////////////
            //HOJAS DINAMICAS
            let longitud=items.length-1;
            let k;
         
            for(k=1; k<longitud; k++){  
              if(items[k].ExcelJson.length > 0) {
                titulo= items[k].ExcelTabName;
                var worksheet2 = workbook.addWorksheet(titulo);
                //INFO BUQUE
          
                y = 0;
                letra = 12;
                var columnsDefs2 = [];
                var keyColumns2 = [];
                bandera = true;
          
                keyColumns2 = Object.keys(rowData[0].hojaDinamica[k-1].hoja[0]);
                
                if(this.bulkCarrier == 1){
                    columnsDefs2 = [
                        this.$t('label.code'),
                        this.$t('label.description')+' (EN)',
                        this.$t('label.description')+' (ES)',
                        this.$t('label.hold'),
                        this.$t('label.hatchCoverName'),
                        this.$t('label.hatchCoverLocation'),
                        this.$t('label.twinsBox'),
                        this.$t('label.TdrCode'),
                        'BAY SLOT',
                        this.$t('label.transactionDate'),
                        this.$t('label.from'),
                        this.$t('label.to'),
                        this.$t('label.TimeLost'),
                        this.$t('label.responsible')+' (EN)',
                        this.$t('label.responsible')+' (ES)',
                        this.$t('label.beginningTurn'),
                        this.$t('label.finalTurn'),
                        this.$t('label.observation'),
                    ];
                }
                if(this.bulkCarrier == 2){
                    if (this.FgGeneralCargo) {
                        columnsDefs2 = [
                            this.$t('label.code'),
                            this.$t('label.description')+' (EN)',
                            this.$t('label.description')+' (ES)',
                            this.$t('label.type'),
                            this.$t('label.location'),
                            this.$t('label.hold'),
                            this.$t('label.hatchCoverName'),
                            this.$t('label.hatchCoverLocation'),
                            this.$t('label.packaging'), 
                            this.$t('label.totalQuantity'),
                            this.$t('label.machine'),
                            this.$t('label.from'),
                            this.$t('label.to'),
                            this.$t('label.transactionDate'),
                            this.$t('label.from'),
                            this.$t('label.to'),
                            this.$t('label.TimeLost'),
                            this.$t('label.responsible')+' (EN)',
                            this.$t('label.responsible')+' (ES)',
                            this.$t('label.beginningTurn'),
                            this.$t('label.finalTurn'),
                            this.$t('label.lapse'),
                            this.$t('label.observation'),
                        ];
                    }else{
                        columnsDefs2 = [
                            this.$t('label.code'),
                            this.$t('label.description')+' (EN)',
                            this.$t('label.description')+' (ES)',
                            this.$t('label.hold'),
                            this.$t('label.weight'),
                            this.$t('label.hatchCoverName'),
                            this.$t('label.hatchCoverLocation'),
                            this.$t('label.machine'),
                            this.$t('label.from'),
                            this.$t('label.to'),
                            this.$t('label.transactionDate'),
                            this.$t('label.from'),
                            this.$t('label.to'),
                            this.$t('label.TimeLost'),
                            this.$t('label.responsible')+' (EN)',
                            this.$t('label.responsible')+' (ES)',
                            this.$t('label.beginningTurn'),
                            this.$t('label.finalTurn'),
                            this.$t('label.lapse'),
                            this.$t('label.observation'),
                        ];
                    }
                }   

                bandera = false;
                y = columnsDefs2.length;
                if (y < 10) letra = 8;
                worksheet2.mergeCells('A1:B2');
                worksheet2.getCell("A1").border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
          
                worksheet2.addImage(imageId, 'A1:B2');
          
                rowIndex1 = worksheet2.getRow(1);
                rowIndex1.height = 100;
          
                worksheet2.mergeCells("C1:" + numeroLetra(y) +"1");
                const customCellCompanyBranch = worksheet2.getCell("C1");

                row = worksheet2.getRow(1);
                row.height = 30;
                customCellCompanyBranch.font = {
                    name: "Calibri",
                    family: 4,
                    size: letra,
                    underline: false,
                    bold: true
                };
                customCellCompanyBranch.note = {
                    margins: {
                        insetmode: 'auto',
                        inset: [10, 10, 5, 5]
                    }
                }
                customCellCompanyBranch.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'f2f2f2'
                    }
                }
                customCellCompanyBranch.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
                customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

                let row3 = worksheet2.getRow(2);   
                
                row3.height = 30;
                worksheet2.mergeCells("C2:" + numeroLetra(y) +"2");
                const customCellTitulo = worksheet2.getCell("C2");  
                customCellTitulo.font = {
                    name: "Calibri",
                    family: 4,
                    size: letra,
                    underline: false,
                    bold: true
                };      
          
                customCellTitulo.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'f2f2f2'
                    },
                }
          
                customCellTitulo.border = {
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                    
                customCellTitulo.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
                customCellTitulo.value = tituloExcel;
          
                //printed
                worksheet2.mergeCells("A3:"+numeroLetra(Math.floor(y/2))+"3");
                customCell2 = worksheet2.getCell("A3");
                customCell2.height = 50;
                customCell2.font = {
                    name: "Calibri",
                    family: 4,
                    size: letra,
                    underline: false,
                    bold: true
                };
                customCell2.border = {
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                customCell2.alignment = { vertical: 'middle', horizontal: 'center' }
                customCell2.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy);//hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });

                //user
                worksheet2.mergeCells(numeroLetra(Math.floor(y/2)+1)+"3:"+numeroLetra(y)+"3");
                customCell4 = worksheet2.getCell(numeroLetra(Math.floor(y/2)+1)+"3");
                customCell4.height = 50;
                customCell4.font = {
                    name: "Calibri",
                    family: 4,
                    size: letra,
                    underline: false,
                    bold: true
                };
                customCell4.border = {
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                customCell4.alignment = { vertical: 'middle', horizontal: 'center' }
                customCell4.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
          
                worksheet2.mergeCells("A4:"+numeroLetra(y)+"4");
                customCell5 = worksheet2.getCell("A4");
                customCell5.height = 50;
                customCell5.alignment = { vertical: 'middle', horizontal: 'center' }

                if (this.FgGeneralCargo) {
                    worksheet2.mergeCells("O5:Q5");
                    var customCell6 = worksheet2.getCell("O5");
                    customCell6.height = 50;
                    customCell6.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCell6.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCell6.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCell6.value = this.$t('label.inactivityTime');
                } else if(this.bulkCarrier == 1){
                    worksheet2.mergeCells("K5:M5");
                    var customCell6 = worksheet2.getCell("K5");
                    customCell6.height = 50;
                    customCell6.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCell6.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCell6.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCell6.value = this.$t('label.inactivityTime');
                } else if(this.bulkCarrier == 2){
                    worksheet2.mergeCells("L5:N5");
                    var customCell6 = worksheet2.getCell("L5");
                    customCell6.height = 50;
                    customCell6.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCell6.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCell6.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCell6.value = this.$t('label.inactivityTime');
                }
          
                var headerRow = worksheet2.addRow();
                headerRow.font = {
                    name: "Calibri",
                    family: 4,
                    size: 11,
                    underline: false,
                    bold: true
                };
                
                i = 0;
                columnsDefs2.map(function (data) {
                  let valor = bandera ? data.headerName.length : data.length;
                  let long = 20;
                  if ((valor >= 11) && (valor <= 18)) {
                      long = 25;
                  } else if ((valor >= 19) && (valor <= 26)) {
                      long = 30;
                  } else if (valor >= 27) {
                      long = 40;
                  }
          
                  worksheet2.getColumn(i + 1).width = long;
                  let cell = headerRow.getCell(i + 1);
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'cccccc'
                    }
                  }
                  cell.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                  cell.value = data.replace("_"," ");
                  cell.alignment = { vertical: 'middle', horizontal: 'center' }
                  i++;
                })
                worksheet2.autoFilter = {
                  from: 'A6',
                  to: numeroLetra(y),
                } 
          
                let indicador =k-1;
                rowData[0].hojaDinamica[indicador].hoja.map(async function (data) {
                  let dataRow = worksheet2.addRow();
                  let indice = 0;
                  let bandera=false;
          
                  keyColumns2.map(function (data2) {      
                    let  cell = dataRow.getCell(indice + 1);
                    
                    cell.value = data[data2];
                    if ((indice!=0 && !isNaN(data[data2])) && (data2=="WEIGHT") ) {
                        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                    }
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
        
                    indice++;
                  })
                })
              }
            }
        
          
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        
            //ULTIMA HOJA
            
            let posFinal= items.length-1;
            titulo= items[posFinal].ExcelTabName;
            const worksheetFinal= workbook.addWorksheet(titulo);
            
            //INFO BUQUE
            y = 0;
            letra = 11;
            columnsDefs = [];
            keyColumns = [];
            bandera = true;
          
            keyColumns = Object.keys(rowData[0].hojaFinal[0]);
         
            columnsDefs = [
                this.$t('label.description')+' (EN)',
                this.$t('label.description')+' (ES)',
                this.$t('label.time'),
                this.$t('label.responsible')+' (EN)',
                this.$t('label.responsible')+' (ES)',
                this.$t('label.benningOperation'),
                this.$t('label.beginningHour'),
                this.$t('label.endOperation'),
                this.$t('label.finalHour'),
                this.$t('label.timeOperations'),
                this.$t('label.TimeLost'),
                this.$t('label.netTime'),
                
            ];

            y = 12;
            if(this.bulkCarrier == 2){
                columnsDefs.push(this.$t('label.weightTotal'));
                y=13;
            }
            
            bandera = false;
          
            if (y < 10) letra = 8;
            worksheetFinal.mergeCells('A1:B2');
            worksheetFinal.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
          
            worksheetFinal.addImage(imageId, 'A1:B2');
          
            rowIndex1 = worksheetFinal.getRow(1);
            rowIndex1.height = 30;
          
            worksheetFinal.mergeCells("C1:"+ numeroLetra(y) +"1");
            worksheetFinal.mergeCells("C2:"+ numeroLetra(y) +"2");
            const customCellCompanyBranchEndPage = worksheetFinal.getCell("C1");

            let rowFinal = worksheetFinal.getRow(1);
            rowFinal.height = 30;
            customCellCompanyBranchEndPage.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellCompanyBranchEndPage.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranchEndPage.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranchEndPage.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranchEndPage.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
            customCellCompanyBranchEndPage.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            let rowBuque = worksheetFinal.getRow(2);
            rowBuque.height = 30;
            let customCellBuque = worksheetFinal.getCell("C2");

            customCellBuque.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };      
          
            customCellBuque.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                },
            }
          
            customCellBuque.border = {
               //top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
                 
            customCellBuque.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
            customCellBuque.value = tituloExcel;
          
            worksheetFinal.mergeCells("A3:"+numeroLetra(Math.floor(y/2))+"3");
            customCell2 = worksheetFinal.getCell("A3");
            customCell2.height = 50;
            customCell2.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell2.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCell2.alignment = { vertical: 'middle', horizontal: 'center' }
            customCell2.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy);//hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
          
            worksheetFinal.mergeCells(numeroLetra(Math.floor(y/2)+1)+"3:"+numeroLetra(y)+"3");
            customCell4 = worksheetFinal.getCell(numeroLetra(Math.floor(y/2)+1)+"3");
            customCell4.height = 50;
            customCell4.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCell4.alignment = { vertical: 'middle', horizontal: 'center' }
            customCell4.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
          
            worksheetFinal.mergeCells("A4:"+numeroLetra(y)+"4");
            customCell5 = worksheetFinal.getCell("A4");
            customCell5.height = 50;
            customCell5.alignment = { vertical: 'middle', horizontal: 'center' }
          
          
            var headerRow = worksheetFinal.addRow();
            headerRow.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };
            
            i = 0;
            columnsDefs.map(function (data) {
              let valor = bandera ? data.headerName.length : data.length;
              let long = 10;
              if ((valor >= 5) && (valor <= 12)) {
                  long = 20;
              } else if ((valor >= 11) && (valor <= 18)) {
                  long = 25;
              } else if ((valor >= 19) && (valor <= 26)) {
                  long = 30;
              } else if (valor >= 27) {
                  long = 40;
              }
          
              worksheetFinal.getColumn(i + 1).width = long;
              let cell = headerRow.getCell(i + 1);
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
              }
              cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
              cell.value = data.replace("_"," ");
              cell.alignment = { vertical: 'middle', horizontal: 'center' }
              i++;
            })
          
            rowData[0].hojaFinal.map(async function (data) {
              let dataRow = worksheetFinal.addRow();
              let indice = 0;
              let bandera=false;
              keyColumns.map(function (data2) {
                let  cell = dataRow.getCell(indice + 1);
                
                    cell.value = data[data2];
                    if ((indice!=0 && !isNaN(data[data2])) && (data2=="WEIGHT") ) {
                        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                    }
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
          
                  indice++;
              })
            })
            
              let fileName = documento;
              let today = DateFormater.formatOnlyDateWithSlash(new Date());
              today = today.replaceAll("/", "");
              let time = new Date();
              time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
              //return false;
              workbook.xlsx.writeBuffer()
              .then(buffer => {
                  saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                  this.$notify({
                      group: 'container',
                      text: 'Reporte generado exitosamente',
                      type: "success"
                  });
              }) 
        }

    }else{
       
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.errorsIdleTime'),
            type: 'error',
        });
        
    }
}

export default {
  data,
  methods: {
    getIdleTime,
    numeroLetra,
  },
  computed:{
    ...mapState({
        Visit: state => state.visitas.VisitId,
        dropItemReport: state => state.visitas.dropItemReport,       
        ItineraryId: state => state.visitas.ItineraryId,
        itinerarySelected: state => state.visitas.itinerarySelected,
        user: state => state.auth.user,
        branch: state => state.auth.branch,
        bulkCarrier: state => state.visitas.bulkCarrier,
        FgGeneralCargo: state => state.visitas.FgGeneralCargo,
    }),
  },
}