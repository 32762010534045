<template>
    <div style="margin-top:10px;">
        <CTabs :active-tab="tabIndexGruas" @update:activeTab="handleTab">
            <CTab :title="$t('label.deckCrane')">
                <div style="margin-top:10px;">
                    <DeckCrane :tabIndexGruas="tabIndexGruas"/>
                </div>
            </CTab>
            <CTab :title="$t('label.VesselSide')">
                <div style="margin-top:10px;">
                    <VesselSide :tabIndexGruas="tabIndexGruas"/>
                </div>
            </CTab>
            <CTab :title="$t('label.generalInfo')+' '+$t('label.menu_diary.CRANES')">
                <div style="margin-top:10px;">
                    <tabsGeneral :loading="loading" :tabIndexGruas="tabIndexGruas" />
                </div>
            </CTab>
            <CTab :title="$t('label.transactions')">
                <div style="margin-top:10px;">
                    <transactionsList 
                        :loading="loading" 
                        :tabIndexGruas="tabIndexGruas" 
                        v-if="this.bulkCarrier == 1"
                    />
                    <transactionsBulkCarrierList 
                        :loading="loading" 
                        :tabIndexGruas="tabIndexGruas" 
                        v-if="this.bulkCarrier == 2 && !IsGeneralCargoVessel"
                    />
                    <transactionsGeneralCargoList 
                        :loading="loading" 
                        :tabIndexGruas="tabIndexGruas" 
                        v-if="IsGeneralCargoVessel"
                    />
                </div>
            </CTab>
            <CTab :title="$t('label.timesSheet')" v-if="this.bulkCarrier == 1">
                <div style="margin-top:10px;">
                    <delayList 
                        :loading="loading" 
                        :tabIndexGruas="tabIndexGruas"
                       
                    />
                </div>
            </CTab>
            <CTab :title="$t('label.timesSheet')" v-if="this.bulkCarrier == 2">
                <div style="margin-top:10px;">
                    <delayBulkCarrierList 
                        :loading="loading" 
                        :tabIndexGruas="tabIndexGruas" 
                    />
                </div>
            </CTab>
            <CTab :title="$t('label.detailsmobilizedteams')" v-if="this.bulkCarrier == 1">
                <div style="margin-top:10px;">
                    <detailsMobilizedTeamsList :loading="loading" :tabIndexGruas="tabIndexGruas" />
                </div>
            </CTab>
            <CTab :title="$t('label.delayTimesWithoutMovements')" v-if="this.bulkCarrier == 1">
                <div style="margin-top:10px;">
                    <delayTimesWithoutMovements :loading="loading" :tabIndexGruas="tabIndexGruas" />
                </div>
            </CTab>
        </CTabs>
    </div>
</template>

<script>
    import tabsGeneral from './tabs-general-info';
    import transactionsList from './transactions-list';
    import transactionsBulkCarrierList from './transactions-bulk-carrier-list';
    import transactionsGeneralCargoList from './transactions-general-cargo-list';
    import delayList from './delay-list';
    import delayBulkCarrierList from './delay-bulk-carrier-list';
    import detailsMobilizedTeamsList from './details-mobilized-teams-list';
    import delayTimesWithoutMovements from './delay-times-without-movements-list';
    import DeckCrane from './deck-crane/deck-crane-index';
    import VesselSide from './vessel-side/vessel-side-index.vue';
    import { mapState } from 'vuex';

    //data
    function data() {
        return {
            tabIndexGruas: 0,
        }
    }
    //methods
    function handleTab(tab) {
        this.tabIndexGruas = tab;
    }
    //computed
    function IsGeneralCargoVessel() {
        return this.TpVesselId.toUpperCase() == process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID;
    }
    export default {
        data,
        props:['loading'],
        methods:{
            handleTab
        },
        name:"app-gruas",
        components:{
            transactionsList,
            transactionsBulkCarrierList,
            transactionsGeneralCargoList,
            delayList,
            delayBulkCarrierList,
            detailsMobilizedTeamsList,
            tabsGeneral,
            delayTimesWithoutMovements,
            DeckCrane,
            VesselSide,
        },
        computed:{
            IsGeneralCargoVessel,
            ...mapState({
                dropItemAgenda: state => state.visitas.dropItemAgenda,
                bulkCarrier: state => state.visitas.bulkCarrier,
                branch: state => state.auth.branch,
                TpVesselId: state => state.visitas.itinerarySelected.TpVesselId,
            }),
        },
        watch:{
            dropItemAgenda: async function (newValue, OldValue) {
                if (newValue==2) {
                    this.tabIndexGruas = 0;
                }
                if (OldValue==2) {
                    this.tabIndexGruas = 0;
                }
            },
        }
    }
</script>