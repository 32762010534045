<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.machineries') }}</h6>
            </CCol>
            <CCol sm="12" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm" 
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="3" class="center-field px-1">
                <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-9'}"
                    :label="$t('label.type')"
                    :options="TypeOptionsList"
                    v-model.trim="TpMachineId"
                />
            </CCol>
            <label class="col-sm-12 col-lg-auto col-xl-auto text-right mt-2 pr-1">{{$t('label.date')}}</label>
            <CCol sm="12" lg="2" xl="2" class="center-field px-1">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.fromDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <label class="col-sm-12 col-lg-auto col-xl-auto text-right mt-2">-</label>
            <CCol sm="12" lg="2" xl="2" class="center-field px-1 mb-2">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.toDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <CCol class="col-sm-12 col-lg-auto col-xl-auto center-field px-1" style="text-align: right;">
                <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                    @click="getMachineReport()"
                    >
                    <CIcon name="cil-search" />
                </CButton>
                <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                    @click="refreshComponent()"
                    >
                    <CIcon name="cil-brush-alt" />
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="3" xl="3" class="center-field px-1">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-12 col-lg-3 col-xl-3 text-right', input: 'col-sm-12 col-lg-9 col-xl-9'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)" 
                    v-model="search"           
                >
                </CInput>  
            </CCol>
            <CCol sm="12" lg="5" xl="5">
            </CCol>
            <CCol sm="12" lg="4" xl="4" class="justify-content-end px-1">
                <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-8 col-xl-8 text-right', input: 'col-sm-12 col-lg-4 col-xl-4'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>

        </CRow>
        <CRow>
            <CCol sm="12" lg="12" xl="12" class="mb-2 px-1">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowStyle="getRowStyle"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="RowData"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            rows: [],
            formatedItems: [],
            TpMachineId: '',
            TpMachineOptions: [],
            defaultColDef: null,
            gridApi: null,
            columnApi: null,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            previousDate: new Date(),
            laterDate: new Date(),
            searchFilterDate:{
                fromDay: '',
                toDay: '',
            },
            disabledFilterByPreference: false,
            pageSize:'',
            search: '',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    //methods
    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function getRowClass(params) {
        if (params.node.data.section=="header") {
            return 'bg-secondary font-weight-bold bg-gradient'
        }
        return 'bg-gradient';
    }

    function getRowStyle(params) {
        return { background: params.node.data.ColorStatus };
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.formatedItems.length !== 0) {
            await this.getPdf(rowData,this.$t('label.machineries'));
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        let currentDate = new Date();
        let rowData = [];
        let valores = [];
        let myDataTpMachine = this.TpMachineOptions.find(item => item.TpMachineId == this.TpMachineId)
        valores[0] = this.$t('label.type')+": "+(myDataTpMachine ? myDataTpMachine.TpMachineName : 'N/A');
        valores[1] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        valores[2] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(rowData.length !== 0) {
            await this.getOrdersExcel(rowData,this.$t('label.machineries'),valor, valores);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
        
    }

    async function getMachineReport () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = [];
        let dateStart = DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        let dateEnd = DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);
        let ReportJson = [{
            VisitId: this.Visit,
            TpMachineId: this.TpMachineId,
            DateStart: dateStart,
            DateEnd: dateEnd,
        }];
        await this.$http.post("VisitMachineReport", ReportJson, { root: 'ReportJson' })
        .then(response => {
            listado = response.data.data;
            if (listado&&Object.keys(listado[0]).length>1) {
                listado.map(item => {
                    this.formatedItems.push({
                        Nro: item.Nro ? item.Nro : '',
                        ClientName: item.ClientName ? item.ClientName : '',
                        MachineName: item.MachineName ? item.MachineName : '',
                        TpMachineName: item.TpMachineName ? item.TpMachineName : '',
                        PlannedHrs: item.PlannedHrs ? item.PlannedHrs : '',
                        WorkedHrs: item.WorkedHrs ? item.WorkedHrs : '',
                    })
                })
            }
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    function MachineTpList() {
        this.loadingOverlay = true;  
        this.$http.get("VisitMachineTpList-by-VisitId", { VisitId: this.Visit })
        .then(response => {
            let listado = response.data.data;
            if (listado&&listado.length!=0) {
                this.TpMachineOptions = listado;
            }
            this.getMachineReport();
        }).catch( err => {
            this.loadingOverlay = false;
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
    }

    async function refreshComponent() {
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.disabledFilterByPreference= false;
        this.pageSize="";
        this.gridApi.paginationSetPageSize(parseInt(50));
        this.searchFilterDate.fromDay = '';
        this.searchFilterDate.toDay = '';
        this.formatedItems = [];
        this.TpMachineId = '';
        this.TpMachineOptions = [];
        await this.getDateRange();
        await this.MachineTpList();
    }

    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }

    //computed
    function RowData(){
        if(this.formatedItems.length != 0){
            return this.formatedItems.map((item) => {
                return {
                    ...item,
                };
            });
        }else{
            return [];
        }
    }

    function TypeOptionsList(){
        if(this.TpMachineOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            let chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.TpMachineOptions.map(function(e){
                chart.push({
                    value: e.TpMachineId, 
                    label: e.TpMachineName,
                })
            })
            return chart;
        }
    }

    function columnDefs(){
        let columnDefs = [
                {
                    field: "Nro",
                    headerName: "#",
                    filter: 'agNumberColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 70,
                    maxWidth: 70,
                    cellClass: 'gb-cell',
                },
                {
                    field: "ClientName",
                    headerName: this.$t('label.supplier'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    minWidth: 180,
                    cellClass: 'gb-cell text-color-negrita',
                },
                {
                    field: "MachineName",
                    headerName: this.$t('label.machine'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    minWidth: 170,
                    cellClass: 'gb-cell',
                },
                {
                    field: "TpMachineName",
                    headerName: this.$t('label.type'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 170,
                    cellClass: 'gb-cell',
                },
                {
                    field: "PlannedHrs",
                    headerName: this.$t('label.PlannedWorked'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 200,
                    cellClass: 'gb-cell',
                },
                {
                    field: "WorkedHrs",
                    headerName: this.$t('label.HoursWorked'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 200,
                    cellClass: 'gb-cell',
                }, 
            ]
            return columnDefs;
    }

    function Header(params) {
        return params.data.section === 'header';
    }

    export default {
        name: "machinery-report",
        data,
        beforeMount,
        mixins: [ReportesVisitas, AgGrid],
        methods:{
            MachineTpList,
            getDateRange,
            validateDateRange,
            onBtnPdf,
            onGridReady,
            onBtnExport,
            getMachineReport,
            onQuickFilterChanged,
            onPageSizeChanged,
            getRowStyle,
            getRowClass,
            refreshComponent,
        },
        computed:{
            TypeOptionsList,
            RowData,
            Header,
            columnDefs,
            ...mapState({
                user: state => state.auth.user,
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,  
                itinerarySelected: state => state.visitas.itinerarySelected,
            }),
        },

        watch:{
            dropItemReport: async function (newValue) {
                if (newValue==5 && this.Visit != '') {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>
<style src="./css/reporte.css"></style>