var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('loading-overlay',{attrs:{"active":_vm.loadingOverlay,"is-full-page":true,"loader":"bars"}}),(_vm.generar)?_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('generar-baplie',{attrs:{"valor":_vm.generar},on:{"child-refresh":function($event){_vm.refrescarTabla=true}}})],1)],1):_vm._e(),(!_vm.generar)?_c('CRow',[_c('CCol',{staticClass:"bg-watch d-flex align-items-center form-inline py-2",attrs:{"sm":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('label.name'))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control w-50 ml-2 oscuro",attrs:{"disabled":""},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_c('CCol',{staticClass:"bg-watch d-flex align-items-center form-inline",attrs:{"sm":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('label.documentType'))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.documentType),expression:"documentType"}],staticClass:"form-control w-50 ml-2 oscuro",attrs:{"disabled":""},domProps:{"value":(_vm.documentType)},on:{"input":function($event){if($event.target.composing)return;_vm.documentType=$event.target.value}}})]),_c('CCol',{staticClass:"text-center mt-2",attrs:{"sm":"12"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('label.bapliesList'))+" ")])]),_c('CCol',{staticClass:"text-right mb-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: ' BAPLIE',
          placement: 'top',
        }),expression:"{\n          content: ' BAPLIE',\n          placement: 'top',\n        }"}],attrs:{"square":"","color":"add"},on:{"click":function($event){_vm.generar=true}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","fields":_vm.fields,"items":_vm.items,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.loadingTable,"hover":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Nro",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_vm._v(" "+_vm._s(item.Nro)+" ")])]}},{key:"EdiFileName",fn:function({item}){return [_c('td',[_vm._v(" "+_vm._s(item.EdiFileName)+" ")])]}},{key:"EdiVersion",fn:function({item}){return [_c('td',[_vm._v(" "+_vm._s(item.EdiVersion)+" ")])]}},{key:"Usuario",fn:function({item}){return [_c('td',[_vm._v(" "+_vm._s(item.Usuario)+" ")])]}},{key:"Fecha",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_vm._v(" "+_vm._s(item.Fecha)+" ")])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"Detalle",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.download')+' BAPLIE',
                  placement: 'top',
                }),expression:"{\n                  content: $t('label.download')+' BAPLIE',\n                  placement: 'top',\n                }"}],attrs:{"size":"sm","color":"excel"},on:{"click":function($event){return _vm.descargarArchivo(item)}}},[_c('CIcon',{attrs:{"name":"cil-cloud-download"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.delete')+' BAPLIE',
                  placement: 'left',
                }),expression:"{\n                  content: $t('label.delete')+' BAPLIE',\n                  placement: 'left',\n                }"}],staticClass:"mx-1",attrs:{"size":"sm","color":"wipe"},on:{"click":function($event){return _vm.ConfirmarEliminado(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}],null,false,3775712192)})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }