<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
       
        <CRow class="mb-3">
            <CCol sm="12"  lg="12" xl="12" class="d-flex align-items-center justify-content-end" style="margin-bottom:10px">
                <div class="text-right">
                    <CButton 
                        color="danger"
                        class="mr-1"
                        size="sm"
                        @click="onBtnPdf()"
                        >
                        <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                    </CButton>
                    <CButton
                        color="excel"
                        class="mr-1"
                        size="sm"
                        @click="onBtnExport(true)"
                    >
                        <CIcon name="cil-file-excel"/>&nbsp; XLSX
                    </CButton>
                    <CButton
                        color="watch"
                        size="sm"
                        @click="onBtnExport(false)"
                    >
                        <CIcon name="cil-file-excel"/>&nbsp; CSV
                    </CButton>
                </div>
            </CCol>
            </CRow>
            <CRow>
                <CCol sm="11" lg="3" xl="3" class="center-field  ">
                    <CSelect
                        :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                        :label="$t('label.crane')"
                        :options="craneListFormatted"
                        v-model.trim="VisitCraneId"
                        :value.sync="VisitCraneId"
                        @change="getDelayList()"
                    />
                </CCol>
                <label class="col-sm-11 col-lg-1 col-xl-1 text-right mt-2">{{$t('label.date')}}</label>
                <CCol sm="11" lg="2" xl="2" class="center-field px-1">
                    <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.fromDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
                </CCol>
                <label class="col-sm-11 col-lg-auto col-xl-auto text-right mt-2">-</label>
                <CCol sm="11" lg="2" xl="2" class="center-field px-1 mb-2">
                    <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.toDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
                </CCol>
                <CCol class="col-sm-11 col-lg-auto col-xl-auto center-field mr-0" style="text-align: right;">
                    <CButton
                        color="watch"
                        size="sm"
                        class="mr-1"
                        v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                        @click="getDelayList()"
                        >
                        <CIcon name="cil-search" />
                    </CButton>
                    <CButton
                        color="wipe"
                        class="justify-content-end"
                        size="sm"
                        v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                        @click="refreshComponent()"
                        >
                        <CIcon name="cil-brush-alt" />
                    </CButton>
                </CCol>
            </CRow>
            <CRow>   
                <CCol sm="11" lg="3" xl="3" class="center-field  ">
                    <CInput
                        :label="$t('label.filter')"
                        :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                        :placeholder="$t('label.enterSearch')"
                        @keyup="onQuickFilterChanged($event.target.value)" 
                        v-model="search"  
                    >
                    </CInput>
                </CCol>
                <CCol sm="5" lg="5" xl="5" class="center-field"></CCol>
                <CCol sm="11" lg="4" xl="4" class="  justify-content-end">
                    <CSelect
                        :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                        :label="$t('label.recordsPerPage')"
                        @change="onPageSizeChanged"
                        :options="[50,100,500,1000]"
                        required
                        v-model="pageSize" 
                    >
                    </CSelect>
                </CCol>
            </CRow>
            <CRow> 
                <CCol sm="11" lg="12" xl="12" class="  mb-2">
                    <div class="flex-grow-1 bd-highlight">
                        <ag-grid-vue
                        v-if="showGrid"
                        style="width: 100%; height: 100vh;"
                        class="ag-theme-alpine"
                        :getRowClass="getRowClass"
                        :gridOptions="gridOptions"
                        :defaultColDef="defaultColDef"
                        :localeText="localeText"
                        :columnDefs="columnDefs"
                        :rowData="formatedItems"
                        :suppressRowClickSelection="true"
                        :groupSelectsChildren="true"
                        :enableRangeSelection="true"
                        :pagination="true"
                        :paginationPageSize="paginationPageSize"
                        :paginationNumberFormatter="paginationNumberFormatter"
                        @grid-ready="onGridReady"
                        >
                        </ag-grid-vue>
                    </div>
                </CCol>
            </CRow>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import ENUM from '@/_store/enum';

    //data
    function data() {
        return {
            nulo: '',
            VisitCraneId: '',
            craneListFormatted: [],
            CraneAlias: '', 
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            rows: [],
            formatedItems: [],
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            //dataWeight:[],
            //dataLongitud:[],
            previousDate: new Date(),
            laterDate: new Date(),
            
            search: '',
            pageSize:'',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;        
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function getRowClass(params) {
        if (params.node.data.initRow=="total") {
            return 'bg-secondary font-weight-bold text-white'
        }
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    async function getCraneList () {
        //this.loadingOverlay = true;
        this.craneListFormatted = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("VisitVesselCrane-list?VisitId="+this.Visit)
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.craneListFormatted.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.craneListFormatted.push({
                    value: listado[i].VisitCraneId,
                    label: listado[i].CraneAlias,
                })
            }
            //this.loadingOverlay = false;
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            //this.loadingOverlay = false;
        });
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.formatedItems.shift();
        this.formatedItems.unshift({
            IdX: '',
            Line: '',
            CraneAlias: '',
            CustomCode: '',
            DelayCustomNameEn: '',
            DelayCustomNameEs: '',
            Quantity: '',
            VesselHoldName: '',
            ResponsibleNameEn: '',
            ResponsibleNameEs: '',
            TransactionBegin: '',
            TransactionFinish: '',
            TotalTime: '',
            c20Full: 'FULL',
            c40Full: 'FULL',
            c45Full: 'FULL',
            c20Empty: 'EMPTY',
            c40Empty: 'EMPTY',
            c45Empty: 'EMPTY',
            Total: '',
            DeviceCode: '',
            TransaLogin: '',

        })
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getPdf(rowData,this.$t('label.timesSheetReport'), 'delayList');
        }
        else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        let rowData = [];
        if (this.CraneAlias == null || this.CraneAlias =='') this.CraneAlias=this.$t('label.ALL'); 
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.nulo!="null" && this.formatedItems.length !== 0){
            await this.getTimeReport(rowData,this.$t('label.timesSheetReport'),valor,this.CraneAlias, this.searchFilterDate.fromDay, this.searchFilterDate.toDay, true);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
    }

    async function buscarCrane(e) {
        this.VisitCraneId=e.target.value;
        this.loadingOverlay = true;
        await this.getDelayList();
        this.loadingOverlay = false;
    }

    async function getDelayList () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;
        let dateStart = '';
        let dateEnd = '';

        this.getDateRange();

        dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);

        let TimeReportJson = {
            VisitId: this.Visit,
            VisitCraneId: this.VisitCraneId,
            DateStart:dateStart,
            DateEnd:dateEnd,
        };

        await this.$http.post("VisitCraneTimeReport", TimeReportJson, { root: 'TimeReportJson' })
        .then(response => {
            listado = [...response.data.data];
            this.nulo = String(response.data.data[0].Json);
            if (this.nulo=="null") return false;
            this.formatedItems.push({
                initRow: 'total', 
                c20Full: 'FULL',
                c20Empty: 'EMPTY',
                total: 'DELAY_LIST',
            });
            
            if(listado.length !== 0  && this.nulo!="null"){
                this.CraneAlias = listado[0].CraneAlias;
                for (let i=0; i < listado.length; i++) {

                    this.formatedItems.push({
                        IdX: listado[i].IdX,
                        CraneAlias: listado[i].CraneAlias ? listado[i].CraneAlias : '',
                        CustomCode: listado[i].CustomCode? listado[i].CustomCode : '',
                        DelayCustomNameEn: listado[i].DelayCustomNameEn ? listado[i].DelayCustomNameEn : '',
                        DelayCustomNameEs: listado[i].DelayCustomNameEs ? listado[i].DelayCustomNameEs : '',
                        TransactionBegin: listado[i].TransactionBegin ? DateFormater.formatDateTimeWithSlash(listado[i].TransactionBegin) : '',
                        TransactionFinish: listado[i].TransactionFinish ? DateFormater.formatDateTimeWithSlash(listado[i].TransactionFinish) : '',
                        TotalTime: listado[i].TotalTime ? listado[i].TotalTime : '',
                        c20Full: listado[i].c20Full ? listado[i].c20Full : 0,
                        c40Full: listado[i].c40Full ? listado[i].c40Full : 0,
                        c45Full: listado[i].c45Full ? listado[i].c45Full : 0,
                        c20Empty: listado[i].c20Empty ? listado[i].c20Empty : 0,
                        c40Empty: listado[i].c40Empty ? listado[i].c40Empty : 0,
                        c45Empty: listado[i].c45Empty ? listado[i].c45Empty : 0,
                        Line: listado[i].Line ? listado[i].Line : '',
                        Total: listado[i].Total ? listado[i].Total : 0,
                        ResponsibleNameEn: listado[i].ResponsibleNameEn ? listado[i].ResponsibleNameEn : '',
                        ResponsibleNameEs: listado[i].ResponsibleNameEs ? listado[i].ResponsibleNameEs : '',
                        Quantity: listado[i].Quantity ? listado[i].Quantity : '',
                        VesselHoldName: listado[i].VesselHoldName ? listado[i].VesselHoldName : '',
                        TransaLogin: listado[i].TransaLogin ? listado[i].TransaLogin : '',
                        DeviceCode: listado[i].DeviceCode ? listado[i].DeviceCode : '',
                    })
                }
            }
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    function isHeader(params) {
        return params.data.initRow === 'total';
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    async function getData () {
        this.loadingOverlay = true;
        await this.getCraneList();
       // await this.getDateRange();
        await this.getDelayList();
        this.loadingOverlay = false;
    }  
    
    function refreshComponent() {
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.pageSize="";
        this.gridApi.paginationSetPageSize(parseInt(50));
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";
        this.CraneAlias= '';
        this.VisitCraneId= '';
        this.getCraneList();
        this.getDelayList();
    }

    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }

     function columnDefs(){
        let columnDefs = [
                {
                    field: "IdX",
                    headerName: "#",
                    //lockVisible: true,
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    minWidth: 70,
                    cellClass: 'center-cell-especial',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: "Line",
                    //headerName: this.$t('label.line'),
                    headerName: "LINE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 150,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "CraneAlias",
                    headerName: "CRANE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    sortable: true,
                    lockPosition: true,
                    minWidth: 160,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "CustomCode",
                    //headerName: this.$t('label.timeCode'),
                    headerName: "TIME CODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 160,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "DelayCustomNameEn",
                    //headerName: this.$t('label.description')+" (EN)",
                    headerName: "DESCRIPTION (EN)",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 300,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "DelayCustomNameEs",
                    //headerName: this.$t('label.description')+" (ES)",
                    headerName: "DESCRIPTION (ES)",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 300,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "Quantity",
                    //headerName: this.$t('label.timeCode'),
                    headerName: "TWINBOX QUANTITY",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 200,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "VesselHoldName",
                    //headerName: this.$t('label.hold'),
                    headerName: "HOLD",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 160,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "ResponsibleNameEn",
                    //headerName: this.$t('label.responsible')+" (EN)",
                    headerName: "RESPONSIBLE (EN)",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 300,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "ResponsibleNameEs",
                    //headerName: this.$t('label.responsible')+" (ES)",
                    headerName: "RESPONSIBLE (ES)",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 300,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "TransactionBegin",
                    //headerName: this.$t('label.startDate'),
                    headerName: "START DATE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 200,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "TransactionFinish",
                    //headerName: this.$t('label.endDate'),
                    headerName: "END DATE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 200,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "TotalTime",
                    //headerName: this.$t('label.duration'),
                    headerName: "DURATION",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 200,
                    cellClass: 'center-cell-especial bg-secondary',
                    
                },
                {
                    field: "c20Full",
                    headerName: "20'",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 100,
                    colSpan: (params) => {
                        if (isHeader(params)) {
                            return 3;
                        } else {
                            return 1;
                        }
                    },
                    cellClass: (params) => {
                        if (isHeader(params)) {
                            return 'center-cell-especial bg-watch';
                        } else {
                            return 'center-cell-especial';
                        }
                    },
                },
                {
                    field: "c40Full",
                    headerName: "40'",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 100,
                    cellClass: 'center-cell-especial',
                    
                },
                {
                    field: "c45Full",
                    headerName: "45'",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 100,
                    cellClass: 'center-cell-especial',
                    
                },
                {
                    field: "c20Empty",
                    headerName: "20'",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 100,
                    cellClass: 'center-cell-especial',
                    colSpan: (params) => {
                        if (isHeader(params)) {
                            return 3;
                        } else {
                            return 1;
                        }
                    },
                    cellClass: (params) => {
                        if (isHeader(params)) {
                            return 'center-cell-especial bg-plan';
                        } else {
                            return 'center-cell-especial';
                        }
                    },   
                },
                {
                    field: "c40Empty",
                    headerName: "40'",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 100,
                    cellClass: 'center-cell-especial',
                    colSpan: (params) => {
                        if (isHeader(params)) {
                            return 3;
                        } else {
                            return 1;
                        }
                    },
                },
                {
                    field: "c45Empty",
                    headerName: "45'",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 100,
                    cellClass: 'center-cell-especial',
                    colSpan: (params) => {
                        if (isHeader(params)) {
                            return 4;
                        } else {
                            return 1;
                        }
                    },
                },
                {
                    field: "Total",
                    //headerName: this.$t('label.equipmentTotal'),
                    headerName: "EQUIPMENT TOTAL",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 200,
                    cellClass: 'center-cell-especial bg-secondary',
                },
                {
                    field: "DeviceCode",
                    //headerName: this.$t('label.equipmentTotal'),
                    headerName: "DEVICE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 200,
                    cellClass: 'center-cell-especial bg-secondary',
                },
                {
                    field: "TransaLogin",
                    //headerName: this.$t('label.equipmentTotal'),
                    headerName: "USER",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 200,
                    cellClass: 'center-cell-especial bg-secondary',
                },
            ]
           return columnDefs;
    }

    export default {
        name: "delay-list",
        data,
        beforeMount,
        mounted,
        mixins: [ReportesVisitas, AgGrid],
        props: {
            tabIndexGruas: null,
        },
        methods:{
            onGridReady,
            onBtnExport,
            getDelayList,
            getData,
            getCraneList,
            buscarCrane,
            onQuickFilterChanged,
            onPageSizeChanged,
            getRowClass,
            onBtnPdf,
            validateDateRange,
            refreshComponent,
            getDateRange
        },
        computed:{
            isHeader,
            columnDefs,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,       
                dropItemAgenda: state => state.visitas.dropItemAgenda,       
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                user: state => state.auth.user,
                branch: state => state.auth.branch,
                bulkCarrier: state => state.visitas.bulkCarrier,
            }),
        },
        components:{
            
        },
        watch:{            
            tabIndexGruas: async function(val){
                if (val==4 && this.bulkCarrier == 1 && this.Visit != '') {
                    await this.refreshComponent();
                   // this.$emit('child-refresh',true);
                }
            },    
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }        
        }
    };
</script>
<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>
