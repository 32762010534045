<template>
    <div>
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <div>
            <div class="visitas-body">
                <div class="wrapper d-flex align-items-stretch">
                    <nav id="sidebar-visit">
                        <div class="img bg-wrap text-center py-4" :style="'background-image: url('+imageSrc(itinerarySelected.VesselRoute)+')'">
                        </div>

                        <div class="p-3">
                            <ul class="list-unstyled components mb-5">
                                <li>
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="10">
                                                <h5 class="text-white mt-0">
                                                    {{itinerarySelected.VesselName}}                                                    
                                                </h5>
                                                <h6 class="text-white mt-0">
                                                    {{ itinerarySelected.TpVesselName }}                                                    
                                                </h6>
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>

                                <li>
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{$t('label.flag')}}
                                            <!--/CCol>
                                            <CCol sm="3" class="d-flex align-items-center"-->
                                                <img
                                                    :src="imageSrc(itinerarySelected.VesselFlagRoute)"
                                                    alt="France"
                                                    width="40px"
                                                    height="50px"
                                                    class="img-thumbnail ml-1"
                                                    
                                                />
                                            </CCol>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{itinerarySelected.CountryName}}
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>
                                <li>
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                MMSI
                                            </CCol>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{ itinerarySelected.Mmsi }}
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>
                                <li>
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                IMO
                                            </CCol>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{ itinerarySelected.Imo }}
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>
                                <li v-if="(bulkCarrier == 1 || FgGeneralCargo) && itinerarySelected?.VesselTeus > 0">
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                TEUS
                                            </CCol>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{itinerarySelected.VesselTeus}}
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>
                                <li>
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                LOA
                                            </CCol>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{itinerarySelected.Loa}}&nbsp;MTS
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>
                                <li>
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{$t('label.stern')}}
                                            </CCol>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{ itinerarySelected.Popa }}&nbsp;MTS
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>
                                <li>
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{$t('label.bow')}}
                                            </CCol>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{ itinerarySelected.Proa }}&nbsp;MTS
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>
                                <li>
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{$t('label.gross')}}
                                            </CCol>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{ itinerarySelected.Gross }}&nbsp;TON
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>
                                <li>
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{$t('label.service')}}
                                            </CCol>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{ itinerarySelected.ServiceCode }}
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>
                                <li>
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{$t('label.voyage')}}
                                            </CCol>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{ itinerarySelected.Voyage }}
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>
                                <li>
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{$t('label.destination')}}
                                            </CCol>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{ itinerarySelected.PortDestinationCode }}
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>
                                <li>
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                ETA&nbsp;<CIcon name="cil-arrow-right"/>
                                            </CCol>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{ formaterNormalDate(itinerarySelected.Eta) }}
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>
                                <li>
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                ETD&nbsp;<CIcon name="cil-arrow-left"/>
                                            </CCol>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{ formaterNormalDate(itinerarySelected.Etd) }}
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>
                                <li>
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                ATA&nbsp;<CIcon name="cil-arrow-right"/>
                                            </CCol>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{ formaterNormalDate(itinerarySelected.Ata) }}
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>
                                <li>
                                    <div class="list-menu">
                                        <CRow>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                ATD&nbsp;<CIcon name="cil-arrow-left"/>
                                            </CCol>
                                            <CCol sm="6" class="d-flex align-items-center">
                                                {{ formaterNormalDate(itinerarySelected.Atd) }}
                                            </CCol>
                                        </CRow>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>


                    <!-- Page Content  -->
                        
                    <div id="content">
                        <nav
                            class="navbar navbar-expand-lg navbar-light bg-light"
                        >
                            <div class="container-fluid">

                                <button type="button" id="sidebarCollapseVisita" class="btn bg-none text-withe" @click="checkSideBarToggle()">
                                    <CIcon  class="text-white" :name="checkIconToggle"/>
                                    <span class="sr-only">{{checkTextToggle}}</span>
                                </button>
                                <!--
                                <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <CIcon name="cil-chevron-circle-left"/>
                                </button>
                                -->
                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul class="nav navbar-nav ml-auto w-100 justify-content-end">
                                        <li class="nav-item active d-flex align-items-center justify-content-end" style="margin-right: 10px;">
                                            <a>{{$t('label.statusVisit')}}</a>
                                        </li>
                                        <li class="nav-item d-flex justify-content-center align-items-center" style="margin-right: 10px;width: 23%;">
                                            <input disabled type="text" class="form-control" :value="textEspecial" placeholder="">
                                        </li>
                                        <li class="nav-item d-flex align-items-center justify-content-end" style="margin-right: 10px;width: 10%">
                                            <a>{{$t('label.itinerary')}}</a>
                                        </li>
                                        <li class="nav-item d-flex justify-content-center align-items-center" style="margin-right: 10px;width: 44%">
                                            <input disabled type="text" class="form-control" :value="textItinerario" placeholder="" >
                                        </li>
                                        <li v-on:click="closeCollapse()" class="nav-item d-flex align-items-center justify-content-end text-white">
                                            <CIcon name="x"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                        <div class="container-fluid contenido-especial">
                            <CRow>
                                <CCol sm="12">
                                    <CustomTabs :active-tab="$store.state.visitas.tabIndex" @update:activeTab="handleTab">

                                       <!-- Dinamico -->
                                       <CustomTab 
                                            :disabled="apiStateLoading || disabledTabState(main.TabUnique)"
                                            v-for="(main , index) in menuOptions"
                                            :key="main.ModuleRoleId"
                                        >
                                            <template #title>

                                                <CIcon v-if="main.Icon" :name="main.Icon" class="mr-1"/>
                                                <img v-if="main.OptionSrc" class="ml-3 mr-1" width="100%" height="100%" :src="main.OptionSrc" alt="Card image cap">
                                                
                                                <span
                                                    v-if="main.L2Json.length == 0"
                                                    class="ml-1"
                                                    @click="setActiveItem(main.TabUnique)"
                                                >
                                                    {{titleLocalize(main)}}
                                                </span>

                                                <CDropdown
                                                    v-if="main.L2Json.length > 0"
                                                    :togglerText="titleLocalize(main)"
                                                    class="text-white pl-0"
                                                    :flip="false"
                                                    :disabled="apiStateLoading || disabledTabState(main.TabUnique)"                            
                                                >
                                                    <CDropdownItem
                                                        v-for="(second, y) in main.L2Json"
                                                        :key="second.ModuleRoleId"
                                                        :active="checkActiveItemByGpoModuleNameEn(second.TabUnique)"
                                                        :disabled="apiStateLoading || disabledTabState(second.TabUnique)"
                                                        @click="setActiveItem(second.TabUnique, second, 2)"
                                                    >
                                                        <span 
                                                            v-if="second.L3Json.length == 0"
                                                        >
                                                            {{titleLocalize(second)}}
                                                        </span>

                                                        <nav
                                                            v-else-if="second.L3Json.length > 0"
                                                            id="menu_gral"
                                                        >
                                                            <ul>
                                                                <li class="dropdown_gral">
                                                                    <a href="#">
                                                                        {{titleLocalize(second)}}
                                                                    </a>
                                                                    <ul>
                                                                        <li
                                                                            v-for="(third, y) in second.L3Json"
                                                                            :key="third.ModuleRoleId"
                                                                        >
                                                                            <a
                                                                                href="#"
                                                                                @click.stop.prevent="setActiveItem(third.TabUnique)">
                                                                                    {{titleLocalize(third)}}
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </CDropdownItem>
                                                </CDropdown>
                                            </template>
                                            
                                            <!-- Modulos -->
                                            <!-- GENERAL INFORMATION -->
                                            <div v-if="main.TabUnique == VisitTab.GENERAL_INFORMATION_TAB">
                                                <appGeneral :loading="apiStateLoading" v-show="currentModuleId == VisitTab.GENERAL_INFORMATION_TAB"
                                                    :currentModuleId="currentModuleId == VisitTab.GENERAL_INFORMATION_TAB"/>
                                            </div>
                                            <!-- ITINERARY -->
                                            <div v-if="main.TabUnique == VisitTab.ITINERARY_TAB">
                                                <appItinerario v-show="currentModuleId == VisitTab.ITINERARY_TAB"
                                                    :currentModuleId="currentModuleId == VisitTab.ITINERARY_TAB"/>
                                            </div>
                                            <!-- SCHEDULE -->
                                            <div v-if="main.TabUnique == VisitTab.SCHEDULE_TAB">
                                                <appAgenda :apiStateLoading="apiStateLoading" v-show="currentModuleId == VisitTab.TERMINAL_DETAILS_TAB || currentModuleId == VisitTab.CRANES_TAB"></appAgenda>
                                            </div>
                                            <!-- BILL OF LADING -->
                                            <div v-if="main.TabUnique == VisitTab.BILL_OF_LADING_TAB">
                                                <ManifiestoCarga v-show="currentModuleId == VisitTab.BILL_OF_LADING_TAB"
                                                    :currentModuleId="currentModuleId == VisitTab.BILL_OF_LADING_TAB"
                                                />
                                            </div>
                                            <!-- DRAFT INSPECTIONS -->
                                            <div v-if="main.TabUnique == VisitTab.DRAFT_INSPECTIONS_TAB">
                                                <list-draft v-show="currentModuleId == VisitTab.DRAFT_INSPECTIONS_REGISTER_TAB"/>
                                                <DraftTransport v-show="currentModuleId == VisitTab.DRAFT_TRANSPORT_CERTIFICATION_TAB"/>
                                                <DraftYard v-show="currentModuleId == VisitTab.DRAFT_YARD_CERTIFICATION_TAB"/>
                                            </div>
                                            <!-- SHIPMENT ORDERS -->
                                            <div v-if="main.TabUnique == VisitTab.SHIPMENT_ORDERS_TAB">
                                                <appOrderList v-show="currentModuleId == VisitTab.SHIPMENT_ORDERS_ORDERS_LIST_TAB"/>
                                                <appIncidenceList v-show="currentModuleId == VisitTab.SHIPMENT_ORDERS_INCIDENCE_TAB"/>
                                                <WeighingList v-show="currentModuleId == VisitTab.SHIPMENT_ORDERS_WEIGHING_TAB"/>
                                            </div>
                                            <!-- GENERAL CARGO -->
                                            <div v-if="main.TabUnique == VisitTab.GENERAL_CARGO_TRACKING_TAB">
                                                <appStowageList
                                                    v-show="currentModuleId == VisitTab.GENERAL_CARGO_TRACKING_DISCHARGE_STOWAGE_TAB"
                                                    :currentModuleId="currentModuleId == VisitTab.GENERAL_CARGO_TRACKING_DISCHARGE_STOWAGE_TAB"
                                                />
                                                <appAssignmentList
                                                    v-show="currentModuleId == VisitTab.GENERAL_CARGO_TRACKING_DISCHARGE_CONSIGNEE_IDENTIFICATION_TAB"
                                                    :currentModuleId="currentModuleId == VisitTab.GENERAL_CARGO_TRACKING_DISCHARGE_CONSIGNEE_IDENTIFICATION_TAB"
                                                />
                                                <DirectDischarge
                                                    v-show="currentModuleId == VisitTab.GENERAL_CARGO_TRACKING_DISCHARGE_DIRECT_DISCHARGE_TAB"
                                                    :currentModuleId="currentModuleId == VisitTab.GENERAL_CARGO_TRACKING_DISCHARGE_DIRECT_DISCHARGE_TAB"
                                                />
                                                <appAssignmentShipmentList
                                                    v-show="currentModuleId == VisitTab.GENERAL_CARGO_TRACKING_SHIPMENT_CONSIGNEE_IDENTIFICATION_TAB"
                                                    :currentModuleId="currentModuleId == VisitTab.GENERAL_CARGO_TRACKING_SHIPMENT_CONSIGNEE_IDENTIFICATION_TAB"
                                                />
                                                <appStowageShipmentList
                                                    v-show="currentModuleId == VisitTab.GENERAL_CARGO_TRACKING_SHIPMENT_STOWAGE_TAB"
                                                    :currentModuleId="currentModuleId == VisitTab.GENERAL_CARGO_TRACKING_SHIPMENT_STOWAGE_TAB"
                                                />
                                            </div>
                                             <!-- CONTAINERS-->
                                             <div v-if="main.TabUnique == VisitTab.CONTAINER_TAB">
                                                <appStowageContainerList  
                                                    v-show="currentModuleId == VisitTab.CONTAINER_STOWAGE_TAB"
                                                    :currentModuleId="currentModuleId == VisitTab.CONTAINER_STOWAGE_TAB"
                                                />
                                                <appInspeccion :apiStateLoading="apiStateLoading" 
                                                    v-show="currentModuleId == VisitTab.CONTAINER_INSPECTIONS_TAB"
                                                    :currentModuleId="currentModuleId == VisitTab.CONTAINER_INSPECTIONS_TAB"/>
                                                
                                            </div>
                                            <!-- INSPECTIONS -->
                                            <div v-if="main.TabUnique == VisitTab.INSPECTIONS_TAB">
                                                <appInspeccion :apiStateLoading="apiStateLoading" 
                                                    v-show="currentModuleId == VisitTab.INSPECTIONS_TAB"
                                                    :currentModuleId="currentModuleId == VisitTab.INSPECTIONS_TAB"/>
                                            </div>
                                            <!-- RESOURCES -->
                                            <div v-if="main.TabUnique == VisitTab.RESOURCES_TAB">
                                                <appResource :apiStateLoading="apiStateLoading" v-show="currentModuleId == VisitTab.RESOURCES_RESOURCES_TAB || currentModuleId == VisitTab.RESOURCES_SUPPLIES_TAB ||
                                                    currentModuleId == VisitTab.RESOURCES_MACHINERIES_TAB"></appResource>
                                            </div>
                                            <!-- TIMES -->
                                            <div v-if="main.TabUnique == VisitTab.TIMES_TAB">
                                                <appDelay :loading="apiStateLoading" v-show="currentModuleId == VisitTab.TIMES_VESSEL_TAB"/>
                                                <appDetentionCrane :loading="apiStateLoading" v-show="currentModuleId == VisitTab.TIMES_DETENTION_CRANE_TAB"/>
                                            </div>
                                            <!-- ELECTRONIC DATA -->
                                            <div v-if="main.TabUnique == VisitTab.ELECTRONIC_DATA_TAB">
                                                <appBaplie :loading="apiStateLoading" v-show="currentModuleId == VisitTab.ELECTRONIC_DATA_PRELIMINARY_CUTS_TAB"/>
                                                <appManual :loading="apiStateLoading" v-show="currentModuleId == VisitTab.ELECTRONIC_DATA_PLANE_ADJUSTMENTS_TAB"/>
                                                <full-cargo-view :modulo="'visit'" v-show="currentModuleId == VisitTab.ELECTRONIC_DATA_FULL_CARGO_PLAN_TAB"/>
                                                <bay-view :modulo="'visit'" v-show="currentModuleId == VisitTab.ELECTRONIC_DATA_BAY_VIEW_TAB"/>
                                            </div>
                                            <!-- REPORTS -->
                                            <div v-if="main.TabUnique == VisitTab.REPORTS_TAB">
                                                <appReporte
                                                    :apiStateLoading="apiStateLoading"
                                                    v-show="checkReportType(currentModuleId)"
                                                ></appReporte>

                                                <appInsumos v-show="currentModuleId == VisitTab.REPORTS_SUPPLIES_TAB"></appInsumos>
                                                <MachineryReport v-show="currentModuleId == VisitTab.REPORTS_MACHINERIES_TAB"></MachineryReport>
                                                <appRecursos v-show="currentModuleId == VisitTab.REPORTS_RESOURCES_TAB"></appRecursos>
                                                <appTransacciones v-show="currentModuleId == VisitTab.REPORTS_TRANSACTIONS_CONTAINER_CHIP_TAB"></appTransacciones>
                                                <appTransactionsGeneralCargo 
                                                    :currentModuleId="currentModuleId == VisitTab.REPORTS_TRANSACTIONS_GENERAL_CARGO_TAB"
                                                    v-show="currentModuleId == VisitTab.REPORTS_TRANSACTIONS_GENERAL_CARGO_TAB"
                                                >
                                                </appTransactionsGeneralCargo>
                                                <TransactionsTablets 
                                                    :CurrentModuleId="currentModuleId == VisitTab.REPORTS_TRANSACTIONS_BULK_CARRIER_TAB"
                                                    v-show="currentModuleId == VisitTab.REPORTS_TRANSACTIONS_BULK_CARRIER_TAB"
                                                ></TransactionsTablets>
                                                <appLapseDetail  
                                                    :currentModuleId="currentModuleId == VisitTab.REPORTS_LAPSE_DETAIL_GENERAL_CARGO_TAB"
                                                    v-show="currentModuleId == VisitTab.REPORTS_LAPSE_DETAIL_GENERAL_CARGO_TAB"
                                                >
                                                </appLapseDetail>
                                                <appLapseSummary  
                                                    :currentModuleId="currentModuleId == VisitTab.REPORTS_LAPSE_SUMMARY_GENERAL_CARGO_TAB"
                                                    v-show="currentModuleId == VisitTab.REPORTS_LAPSE_SUMMARY_GENERAL_CARGO_TAB">
                                                </appLapseSummary>
                                                <OrderListGeneralCargo  
                                                    :currentModuleId="currentModuleId == VisitTab.REPORTS_ORDERS_LIST_GENERAL_CARGO_TAB"
                                                    v-show="currentModuleId == VisitTab.REPORTS_ORDERS_LIST_GENERAL_CARGO_TAB"
                                                />
                                            </div>
                                            <!-- END OF OPERATIONS -->
                                            <div v-if="main.TabUnique == VisitTab.END_OF_OPERATIONS_TAB">
                                                <EndOperations v-show="currentModuleId == VisitTab.END_OF_OPERATIONS_TAB"
                                                    :currentModuleId="currentModuleId == VisitTab.END_OF_OPERATIONS_TAB"
                                                ></EndOperations>
                                            </div>
                                            <!-- ERROR'S LOGS -->
                                            <div v-if="main.TabUnique == VisitTab.ERRORS_LOGS_TAB">
                                                <ErrorLog v-show="currentModuleId == VisitTab.ERRORS_LOGS_TAB"
                                                    :currentModuleId="currentModuleId == VisitTab.ERRORS_LOGS_TAB"
                                                ></ErrorLog>
                                            </div>
                                        </CustomTab>

                                    </CustomTabs >
                                </CCol>
                            </CRow>
                        </div>
                    </div>
                </div>
            </div>
            <br><br>
        </div>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import appItinerario from './tabs/app-itinerario';
    import appGeneral from './tabs/general_information/index';
    import CustomTabs from '@/components/tabs/CustomTabs';
    import CustomTab from '@/components/tabs/CustomTab';
    import { DateFormater } from '@/_helpers/funciones';
    import DataStorage from '@/_helpers/localStorage';
    import TdrExcel from '@/_mixins/tdrExcel';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import IdleTime from '@/_mixins/idle-time';
    import Timeslost_StrategicAlliance from '@/_mixins/Timeslost_StrategicAlliance';

    //components
    import ManifiestoCarga from './tabs/bill_of_lading/app-manifiesto-carga';
    import ListDraft from './tabs/draft_inspection/inspections-registre/list-draft';
    import DraftTransport from './tabs/draft_inspection/transport-certification/index';
    import DraftYard from './tabs/draft_inspection/yard-certification/index';
    import appAgenda from './tabs/agenda/index';
    import appReporte from './tabs/reporte/index-reporte';
    import appConsultReporte from './tabs/reporte/consulta-reporte';
    import appResource from './tabs/app-resouce';
    import appTransacciones from './tabs/reporte/index-transacciones';
    import appTransactionsGeneralCargo from './tabs/reporte/index-transactions-general-cargo';
    import TransactionsTablets from './tabs/reporte/transactions-tablets';
    import appInsumos from './tabs/reporte/index-insumos';
    import appRecursos from './tabs/reporte/index-recurso';
    import appBaplie from './tabs/baplie/listado-baplie';
    import appDelay from './tabs/tiempos/index';
    import appDetentionCrane from './tabs/tiempos/index-detention-crane';
    import appInspeccion from './tabs/inspeccion/index-inspeccion';
    import appManual from './tabs/manual_lists/index-manual';
    import appOrderList from './tabs/shipment_orders/order_list/index-order-list';
    import WeighingList from './tabs/shipment_orders/Weighing/weighing-index';
    import appIncidenceList from './tabs/shipment_orders/incidence/index-incidence';
    import ErrorLog from "./tabs/error-log/index";
    import EndOperations from "./tabs/end_operations/end-operations-index";
    import MachineryReport from "./tabs/reporte/machinery-report";
    import appStowageList from './tabs/general-cargo/discharge/stowage/index-stowage'
    import appAssignmentList from './tabs/general-cargo/discharge/assignment/index-assignment'
    import DirectDischarge from './tabs/general-cargo/discharge/direct-discharge/direct-discharge-index'
    import appStowageContainerList from './tabs/containers/stowage/index-stowage'
    import appAssignmentShipmentList from './tabs/general-cargo/shipment/assignment/index-assignment'
    import appStowageShipmentList from './tabs/general-cargo/shipment/stowage/index-stowage'
    import appLapseDetail from './tabs/reporte/index-lapse-detail-general-cargo';
    import appLapseSummary from './tabs/reporte/index-lapse-summary-general-cargo';
    import OrderListGeneralCargo from './tabs/reporte/order-list-general-cargo';

    // Planos componentes
    import FullCargoView from '@/pages/planificacion-estiba/archivos/detalles/full-cargo-view';
    import BayView from '@/pages/planificacion-estiba/archivos/detalles/bay-view';

    //data
    function data() {
        return {
            srcError:'/img/errorimage.jpg',
            dataBuque: {
                CountryName:'',
                VesselTeus:'',
                VesselName:'',
                VesselFlagRoute:'',
                VesselRoute:'',
                ServiceCode:'',
                DatePlanning: '',
                TpVesselName:'',
                Loa:'',
                Teus:'',
                Popa:'',
                Proa:'',
                Ata: "",
                Atd: "",
                Eta:'',
                Etd:'',
                Imo:'',
                Mmsi:'',
            },
            activePage: "Canada",
            collapsed: false,
            verBaplie: false,
            loadingOverlay: false,
            menuOptions: [],
            currentModuleId: 'GENERAL INFORMATION',
        }
    }
    //mounted
    function mounted(){
        document.getElementById("sidebarCollapseVisita").onclick = function() {
            document.getElementById("sidebar-visit").classList.toggle('active');
        }

        this.getMenu();
        this.currentModuleId = this.disabledTabStateIdVisitas ? this.VisitTab.TERMINAL_DETAILS_TAB : this.VisitTab.GENERAL_INFORMATION_TAB;
        if (this.VisitId != '' && this.bulkCarrier == 1) {
            this.$http.get("VisitEdiFile-list", { VisitId: this.VisitId })
            .then(response => {
                if(response.data.data.length !== 0){
                    let listado = response.data.data[0];

                    this.$store.state.visitas.EdiFileId = listado.EdiFileId;
                    this.$store.state.visitas.EdiFileInfo = listado;
                    if (listado!='' || listado!=null) {
                        this.verBaplie = true;
                    }
                }else{
                    this.$notify({
                        group: 'container',
                        title: this.$t('label.Warning'),
                        text: this.$t('label.NoLoadDistributionPlan'),
                        type: "warn",
                    });
                }   
                
            }).catch( err => {
                console.log(err);
            });
        }
    }
    function activeItem(item) {
        return this.dropItemAgenda === item;
    }
    function clickItem(item){
        this.$store.state.visitas.dropItemAgenda  = item;
    }
    function activeItemReport(item) {
        return this.dropItemReport === item;
    }
    function clickItemReport(item){
        this.$store.state.visitas.dropItemReport  = item;
    }
    function activeItemElectronic(item) {
        return this.dropItemElectronic === item;
    }
    function clickItemElectronic(item){
        this.$store.state.visitas.dropItemElectronic  = item;
    }
    function activeItemOrder(item) {
        return this.dropItemOrder === item;
    }
    function clickItemOrder(item){
        this.$store.state.visitas.dropItemOrder  = item;
    }
    function activeItemDratf(item) {
        return this.dropItemDratf === item;
    }
    function clickItemDratf(item){
        this.$store.state.visitas.dropItemDratf  = item;
    }
    function activeItemTime(item) {
        return this.dropItemTime === item;
    }
    function clickItemTime(item){
        this.$store.state.visitas.dropItemTime  = item;
    }
    //Methods
    function formaterNormalDate(date){
        return date ? DateFormater.formatOnlyDateWithSlash(date) : 'N/A' ;
    }
    function handleTab(tab) {
        this.$store.state.visitas.dropItemAgenda = 0;
        this.$store.state.visitas.dropItemReport  = 0;
        this.$store.state.visitas.dropItemOrder  = 0;
        this.$store.state.visitas.dropItemDratf  = 0;
        this.$store.state.visitas.dropItemTime  = 0;
        this.$store.state.visitas.tabIndex = tab;

    }
    function imageSrc(Src){
        if(Src === ''){
            return this.srcError;
        }
        else{
            return `${this.$store.getters["connection/getBase"]}${Src}`;
        }
    }
    function closeCollapse(){
        this.$store.state.visitas.tabIndex=0;
        this.$store.state.visitas.dropItemAgenda = 0;
        this.$store.state.visitas.dropItemReport  = 0;
        this.$store.state.visitas.dropItemOrder  = 0;
        this.$store.state.visitas.dropItemDratf  = 0;
        this.$store.state.visitas.dropItemTime  = 0;
        this.$store.state.visitas.collapseVisits = false;
        this.$store.state.visitas.collapseSidebar = false;
    }
    function checkSideBarToggle(){
        this.collapsed = !this.collapsed;
        this.$store.state.visitas.collapseSidebar = !this.$store.state.visitas.collapseSidebar;
    }
    function textEspecial(){
        if(this.collapseVisits){
            let local = DataStorage.getLocale();
            return local == 'es'? this.itinerarySelected.VisitStatusEs : this.itinerarySelected.VisitStatusEn;
        }else{  
            return '';
        }
    }

    async function newTdr(value) {  
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        this.clickItemReport(7);
      
        if (this.$store.state.visitas.VisitId != '' && this.$store.state.visitas.dropItemReport==7){
            let items= [];
            let nulo = '';
            const id = this.$store.state.visitas.VisitId;
            let ruta = '';

            if(value==7)
                ruta='TerminalDepartureReport-by-VisitId';
            if(value==71)
                ruta='TerminalDepartureDiscountReport-by-VisitId';

            await this.$http.ejecutar('GET', ruta,{ VisitId: id } ).then(response => {
                items = response.data.data;
                nulo = String(response.data.data[0].Json);
                //this.loadingOverlay = false;
            }).catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            //this.loadingOverlay = false;
            }).then(() => {
            //this.isLoading = false;
            });
            if(items.length>0 || items !== null || nulo!="null" )
                await this.getDataTdr(value, items);
        }
        this.$store.state.visitas.apiStateForm = ENUM.INIT;

    }
    async function newIdleTime(value) {  
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        this.clickItemReport(8);

        if (this.$store.state.visitas.VisitId != '' && this.$store.state.visitas.dropItemReport==8){
           
            this.$store.state.visitas.tabIndex=11;
            let items= [];
            let nulo = '';
            let ruta = this.bulkCarrier == 2 || this.FgGeneralCargo ? 'VisitIdleTimeReportBulkCarrier' : 'VisitIdleTimeReport';
            await this.$http.get(ruta, { VisitId: this.VisitId }).then(response => {
                items = response.data.data;
                nulo = response.data.data[0].ExcelJson.length;
            }).catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            }).then(() => {
            });
            if(nulo>0 ){ 
                await this.getIdleTime(items);
            }
            else{ 
                this.$store.state.visitas.apiStateForm = ENUM.INIT;
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: this.$t('label.errorsIdleTime'),
                    type: 'error',
                });
            }

        }
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
    }

    async function newTimeslost_StrategicAlliance(value) {  
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        this.clickItemReport(value);

        if (this.$store.state.visitas.VisitId != '' && this.$store.state.visitas.dropItemReport==value){
            this.$store.state.visitas.tabIndex=11;
            let items= [];
            await this.getTimeslost_StrategicAlliance(items);
        }
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
    }

    async function newOperationalReport(value) {  
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        await this.clickItemReport(value);

        if (this.$store.state.visitas.VisitId != '' && this.$store.state.visitas.dropItemReport==68){
           
            this.$store.state.visitas.tabIndex=11;
            let items= [];
            let nulo = 0;
            let DetailsJson = 0;
            await this.$http.get('VisitBulkCarrierOperationalReport', { VisitId: this.VisitId })
                .then(async response => {
                    items = response.data.data;
                    nulo = items.length;
                    DetailsJson = items[0].DetailsJson.length;
                    if(nulo>0 && DetailsJson>0){ 
                        await this.getOperationalReport(items[0], this.$t('label.operationalReport'), true);
                    }
                    else{ 
                        this.$notify({
                            group: 'container',
                            title: '¡Error!',
                            text: this.$t('label.errorsOperativReport'),
                            type: 'error',
                        });
                    }
                }).catch( err => {
                    this.$notify({
                        group: 'container',
                        title: '¡Error!',
                        text: err,
                        type: "error"
                    });
                })
        }
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
    }

    async function newOperationsSummary() {
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        await this.clickItemReport(40);

        if (this.$store.state.visitas.VisitId != '' && this.$store.state.visitas.dropItemReport==40){

            let items= [];
            let rowData = [];
            let rowDataProvider = [];
            let rowDataProviderQuantity = [];

            await this.$http.get("VisitOperationSummary-by-VisitId", { VisitId: this.VisitId }).then(response => {
                items = response.data.data;
                for (let i = 0; i < items.length; i++) {
                    rowData.push({
                        Ata: items[i].VisitJson[0].Ata ? DateFormater.formatDateTimeWithSlash(items[i].VisitJson[0].Ata) : '',
                        Atd: items[i].VisitJson[0].Atd ? DateFormater.formatDateTimeWithSlash(items[i].VisitJson[0].Atd) : '',
                        Eta: items[i].VisitJson[0].Eta ? DateFormater.formatDateTimeWithSlash(items[i].VisitJson[0].Eta) : '',
                        Etd: items[i].VisitJson[0].Etd ? DateFormater.formatDateTimeWithSlash(items[i].VisitJson[0].Etd) : '',

                        VoyageArrival: items[i].VisitJson[0].VoyageArrival ? items[i].VisitJson[0].VoyageArrival : '',
                        VoyageDeparture: items[i].VisitJson[0].VoyageDeparture ? items[i].VisitJson[0].VoyageDeparture : '',
                        PortDestinationName: items[i].VisitJson[0].PortDestinationName ? items[i].VisitJson[0].PortDestinationName : '',
                        PortOriginName: items[i].VisitJson[0].PortOriginName ? items[i].VisitJson[0].PortOriginName : '',

                        DischargeFull: items[i].DischargeJson[0].DischargeFull ? items[i].DischargeJson[0].DischargeFull : 0,
                        DischargeEmpty: items[i].DischargeJson[0].DischargeEmpty ? items[i].DischargeJson[0].DischargeEmpty : 0,
                        DischargeOog: items[i].DischargeJson[0].DischargeOog ? items[i].DischargeJson[0].DischargeOog : 0,

                        LoadFull: items[i].LoadJson[0].LoadFull ? items[i].LoadJson[0].LoadFull : 0,
                        LoadEmpty: items[i].LoadJson[0].LoadEmpty ? items[i].LoadJson[0].LoadEmpty : 0,
                        LoadOog: items[i].LoadJson[0].LoadOog ? items[i].LoadJson[0].LoadOog : 0,

                        TwinBox: items[i].MobilizedJson[0].TwinBox ? items[i].MobilizedJson[0].TwinBox : 0,
                        HatchCover: items[i].MobilizedJson[0].HatchCover ? items[i].MobilizedJson[0].HatchCover : 0,
                        SealEmptyCont: items[i].MobilizedJson[0].SealEmptyCont ? items[i].MobilizedJson[0].SealEmptyCont : 0,
                    
                        DelaysByVessel: items[i].DelayJson[0].TotalTimeH ? items[i].DelayJson[0].TotalTimeH : 0,
                        DelaysByOperator: items[i].DelayJson[1].TotalTimeH ? items[i].DelayJson[1].TotalTimeH : 0,
                        DelaysPerPort: items[i].DelayJson[2].TotalTimeH ? items[i].DelayJson[2].TotalTimeH : 0,
                        Other: items[i].DelayJson[3].TotalTimeH ? items[i].DelayJson[3].TotalTimeH : 0,
                    });
                };
    
                for (let j = 0; j < items[0].ResourceJson.length; j++) {
                    rowDataProvider.push({
                        item:  items[0].ResourceJson[j].SupplyName,
                        provider:  items[0].ResourceJson[j].ClientName,
                    });
                    rowDataProviderQuantity.push({
                        quantity:  items[0].ResourceJson[j].QuantityUnitMeasureName,
                    });
                }
            }).catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            }).then(() => {});

                if(items.length > 0 ){ 
                await this.getOperationsSummary(rowData[0], rowDataProvider,rowDataProviderQuantity, this.$t('label.operationsSummary'));
                }else{                   
                    this.$store.state.visitas.apiStateForm = ENUM.INIT;
                    this.$notify({
                        group: 'container',
                        title: '¡Error!',
                        text: this.$t('label.errorsIdleTime'),
                        type: 'error',
                    });
                }
            
        }
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
    }

    async function newFinalOperationsReport() {
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        await this.clickItemReport(42);

        if (this.$store.state.visitas.VisitId != '' && this.$store.state.visitas.dropItemReport==42){
            await this.finalOperationsReport();
        }
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
    }

    function getMenu() {
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        this.$http
        .get("ModuleVisitNavigation-by-RoleId", {
            RoleId: this.role ?  this.role.RoleId : '',
            TpVesselId: this.itinerarySelected ? this.itinerarySelected.TpVesselId : '',
        }).then((response) => {
            this.menuOptions = response.data.data;
        }).catch((err) => {
            this.$notify({
                group: "container",
                title: "¡Error!",
                text: err,
                type: "error",
            });
        })
        .then(() => {
            this.$store.state.visitas.apiStateForm = ENUM.INIT;
        });
    }
    function titleLocalize(item){
        return this.$i18n.locale == 'es' ? item.GpoModuleNameEs : item.GpoModuleNameEn;
    }
    function checkActiveItemByGpoModuleNameEn(name){
        return this.currentModuleId == name;
    }
    function setActiveItem(name, item = null, level = 0){
        if(level == 0 && this.disabledTabState(name))
            return;

        if(level == 2 && item.L3Json.length > 0)
            return;

        this.currentModuleId = name;
        switch (name) {
            case this.VisitTab.TERMINAL_DETAILS_TAB: //TERMINAL DETAILS
                this.clickItem(1);
                break;
            case this.VisitTab.CRANES_TAB: // CRANES
                this.clickItem(2);
                break;
            case this.VisitTab.DRAFT_INSPECTIONS_REGISTER_TAB: // INSPECTIONS REGISTER
                this.clickItemDratf(1);
                break;
            case this.VisitTab.DRAFT_TRANSPORT_CERTIFICATION_TAB: //TRANSPORT CERTIFICATION
                this.clickItemDratf(2);
                break;
            case this.VisitTab.DRAFT_YARD_CERTIFICATION_TAB: // YARD CERTIFICATION
                this.clickItemDratf(3);
                break;
            case this.VisitTab.SHIPMENT_ORDERS_ORDERS_LIST_TAB: //ORDERS LIST
                this.clickItemOrder(1);
                break;
            case this.VisitTab.SHIPMENT_ORDERS_INCIDENCE_TAB: //INCIDENCE
                this.clickItemOrder(3);
                break;
            case this.VisitTab.SHIPMENT_ORDERS_WEIGHING_TAB: // WEIGHING
                this.clickItemOrder(4);
                break;
            case this.VisitTab.RESOURCES_RESOURCES_TAB: //Resources
                this.clickItemReport(21);
                break;
            case this.VisitTab.RESOURCES_SUPPLIES_TAB: //Supplies
                this.clickItemReport(22);
                break;
            case this.VisitTab.RESOURCES_MACHINERIES_TAB: //Machineries
                this.clickItemReport(37);
                break;
            case this.VisitTab.TIMES_VESSEL_TAB: //VESSEL
                this.clickItemTime(1);
                break;
            case this.VisitTab.TIMES_DETENTION_CRANE_TAB: //DETENTION CRANE
                this.clickItemTime(3);
                break;
            case this.VisitTab.ELECTRONIC_DATA_FULL_CARGO_PLAN_TAB: // FULL CARGO PLAN
                this.clickItemElectronic(1);
                break;
            case this.VisitTab.ELECTRONIC_DATA_BAY_VIEW_TAB: // BAY VIEW
                this.clickItemElectronic(2);
                break;
            case this.VisitTab.ELECTRONIC_DATA_PRELIMINARY_CUTS_TAB: // PRELIMINARY CUTS
                this.clickItemElectronic(37);
                break;
            case this.VisitTab.ELECTRONIC_DATA_PLANE_ADJUSTMENTS_TAB: // PLANE ADJUSTMENTS
                this.clickItemElectronic(38);
                break;
            // Reportes 1 3 4 6
            case this.VisitTab.REPORTS_SUPPLIES_TAB: //SUPPLIES
                this.clickItemReport(3);
                break;
            case this.VisitTab.REPORTS_RESOURCES_TAB: //RESOURCES
                this.clickItemReport(4);
                break;
            case this.VisitTab.REPORTS_TRANSACTIONS_CONTAINER_CHIP_TAB: //TRANSACTIONS
                this.clickItemReport(6);
                break;
            case this.VisitTab.REPORTS_MACHINERIES_TAB: // MACHINARIES
                this.clickItemReport(5);
                break;
            case this.VisitTab.REPORTS_IDLE_TIME_TAB: //IDLE TIME
                this.newIdleTime(8);
                break;
            case this.VisitTab.REPORTS_TRANSACTIONS_BULK_CARRIER_TAB: //TRANSACTIONS - TABLETS
                this.clickItemReport(50);
                break;
            case this.VisitTab.REPORTS_TRANSACTION_REVERSALS_TAB: //TRANSACTION REVERSALS
                this.clickItemReport(39);
                break;
            case this.VisitTab.REPORTS_INSPECTIONS_DONE_TAB: //INSPECTIONS DONE
                this.clickItemReport(41);
                break;
            case this.VisitTab.REPORTS_WEIGHING_REPORT_TAB: //WEIGHING REPORT
                this.clickItemReport(67);
                break;
            case this.VisitTab.REPORTS_ORDERS_BY_CARRIER_TAB: //ORDERS BY CARRIER
                this.clickItemReport(52);
                break;
            case this.VisitTab.REPORTS_ORDERS_BY_DRIVER_TAB: //ORDERS BY DRIVER
                this.clickItemReport(53);
                break;
            case this.VisitTab.REPORTS_ORDERS_BY_VEHICLE_TAB: //ORDERS BY VEHICLE
                this.clickItemReport(54);
                break;
            case this.VisitTab.REPORTS_ORDERS_BY_BIN_TAB: //ORDERS BY BIN
                this.clickItemReport(55);
                break;
            case this.VisitTab.REPORTS_ORDERS_BY_YARD_TAB: //ORDERS BY YARD
                this.clickItemReport(56);
                break;
            case this.VisitTab.REPORTS_ORDERS_WEIGHT_TAB: //ORDERS - WEIGHT
                this.clickItemReport(65);
                break;
            case this.VisitTab.REPORTS_ORDERS_NOT_WEIGHED_TAB: //ORDERS NOT WEIGHED
                this.clickItemReport(70);
                break;
            case this.VisitTab.REPORTS_ORDERS_BY_LAPSE_TAB: //ORDERS BY LAPSE
                this.clickItemReport(72);
                break;
            case this.VisitTab.REPORTS_INACTIVE_TRUCK_WEIGHING_MOVEMENTS_TAB: //INACTIVE MOVEMENTS
                this.clickItemReport(73);
                break;
            case this.VisitTab.REPORTS_TRUCK_WEIGHING_SCALE_MOVEMENTS_TAB: //TRUCK WEIGHING SCALE MOVEMENTS
                this.clickItemReport(57);
                break;
            case this.VisitTab.REPORTS_BINS_RETURNED_TAB: //BINS RETURNED
                this.clickItemReport(66);
                break;
            case this.VisitTab.REPORTS_DELAY_TIMES_STRATEGIC_ALLIANCE_TAB: //DELAY TIMES - STRATEGIC ALLIANCE
                this.newTimeslost_StrategicAlliance(69)
                break;
            case this.VisitTab.REPORTS_OPERATIONAL_REPORT_TAB: //OPERATIONAL REPORT
                this.newOperationalReport(68);
                break;
            case this.VisitTab.REPORTS_DAILY_REPORT_TAB: //DAILY REPORT
                this.clickItemReport(60);
                break;
            case this.VisitTab.REPORTS_FINAL_REPORT_TAB: //FINAL REPORT
                this.clickItemReport(61);
                break;
            case this.VisitTab.REPORTS_CARRIER_TAB: //CARRIER
                this.clickItemReport(62);
                break;
            case this.VisitTab.REPORTS_PATIO_CLIENTE_TAB: //YARD - CLIENT
                this.clickItemReport(63);
                break;
            case this.VisitTab.REPORTS_VEHICLE_BIN_TAB: //VEHICLE - BIN
                this.clickItemReport(64);
                break;
            case this.VisitTab.REPORTS_CONTAINERS_GENERAL_TAB: //CONTAINERS (GENERAL)
                this.clickItemReport(23);
                break;
            case this.VisitTab.REPORTS_DANGEROUS_CARGO_TAB: //DANGEROUS CARGO
                this.clickItemReport(24);
                break;
            case this.VisitTab.REPORTS_REEFER_CONTAINERS_TAB: //REEFER CONTAINERS
                this.clickItemReport(26);
                break;
            case this.VisitTab.REPORTS_OOG_CARGO_TAB: //OOG CARGO
                this.clickItemReport(36);
                break;
            case this.VisitTab.REPORTS_RESTOW_TAB: //RESTOW
                this.clickItemReport(25);
                break;
            case this.VisitTab.REPORTS_LOAD_TAB: //LOAD
                this.clickItemReport(27);
                break;
            case this.VisitTab.REPORTS_CANCELED_TAB: //CANCELED
                this.clickItemReport(28);
                break;
            case this.VisitTab.REPORTS_DISCHARGE_TAB: //DISCHARGE
                this.clickItemReport(29);
                break;
            case this.VisitTab.REPORTS_VGM_DETAIL_TAB: //VGM DETAIL
                this.clickItemReport(30);
                break;
            case this.VisitTab.REPORTS_PORT_SUMMARY_TAB: //PORT SUMMARY
                this.clickItemReport(34);
                break;
            case this.VisitTab.REPORTS_TYPE_STATUS_CONTAINERS_TAB: //TYPE-STATUS CONTAINERS
                this.clickItemReport(31);
                break;
            case this.VisitTab.REPORTS_TYPE_CONTAINERS_TAB: //TYPE CONTAINERS
                this.clickItemReport(32);
                break;
            case this.VisitTab.REPORTS_PORT_LINE_CONTAINERS_TAB: //PORT-LINE CONTAINERS
                this.clickItemReport(33);
                break;
            case this.VisitTab.REPORTS_DANGEROUS_CARGO_TAB: //DANGEROUS CARGO
                this.clickItemReport(35);
                break;
            case this.VisitTab.REPORTS_TYPE_BY_CRANE_TAB: //TYPE OF LOAD BY CRANE
                this.clickItemReport(38);
                break;
            case this.VisitTab.REPORTS_VESSEL_ACTIVITY_RECAP_TAB: //VESSEL ACTIVITY RECAP
                this.newOperationsSummary();
                break;
            case this.VisitTab.REPORTS_FINAL_OPERATIONS_REPORT_TAB: //FINAL OPERATIONS REPORT
                this.newFinalOperationsReport();
                break;
            case this.VisitTab.REPORTS_TDR_GENERAL_TAB: //GENERAL
                this.newTdr(7);
                break;
            case this.VisitTab.REPORTS_TDR_WITH_DISCOUNTS_TAB: //WITH DISCOUNTS
                this.newTdr(71);
                break;
            default:
                break;
        }
    }
    function checkReportType(name) {
        let types = [this.VisitTab.REPORTS_TDR_WITH_DISCOUNTS_TAB, this.VisitTab.REPORTS_TDR_GENERAL_TAB, this.VisitTab.REPORTS_FINAL_OPERATIONS_REPORT_TAB, this.VisitTab.REPORTS_VESSEL_ACTIVITY_RECAP_TAB, this.VisitTab.REPORTS_TYPE_BY_CRANE_TAB, this.VisitTab.REPORTS_DANGEROUS_CARGO_TAB, this.VisitTab.REPORTS_PORT_LINE_CONTAINERS_TAB, this.VisitTab.REPORTS_TYPE_CONTAINERS_TAB, this.VisitTab.REPORTS_TYPE_STATUS_CONTAINERS_TAB, this.VisitTab.REPORTS_VGM_DETAIL_TAB, this.VisitTab.REPORTS_DISCHARGE_TAB, this.VisitTab.REPORTS_CANCELED_TAB, this.VisitTab.REPORTS_LOAD_TAB, this.VisitTab.REPORTS_RESTOW_TAB, this.VisitTab.REPORTS_OOG_CARGO_TAB, this.VisitTab.REPORTS_REEFER_CONTAINERS_TAB, this.VisitTab.REPORTS_DANGEROUS_CARGO_TAB, this.VisitTab.REPORTS_CONTAINERS_GENERAL_TAB, this.VisitTab.REPORTS_BINS_RETURNED_TAB, this.VisitTab.REPORTS_TRUCK_WEIGHING_SCALE_MOVEMENTS_TAB, this.VisitTab.REPORTS_ORDERS_BY_LAPSE_TAB, this.VisitTab.REPORTS_ORDERS_NOT_WEIGHED_TAB, this.VisitTab.REPORTS_ORDERS_WEIGHT_TAB, this.VisitTab.REPORTS_ORDERS_BY_YARD_TAB, this.VisitTab.REPORTS_ORDERS_BY_BIN_TAB, this.VisitTab.REPORTS_ORDERS_BY_VEHICLE_TAB, this.VisitTab.REPORTS_ORDERS_BY_DRIVER_TAB, this.VisitTab.REPORTS_ORDERS_BY_CARRIER_TAB, this.VisitTab.REPORTS_WEIGHING_REPORT_TAB, this.VisitTab.REPORTS_INSPECTIONS_DONE_TAB, this.VisitTab.REPORTS_TRANSACTION_REVERSALS_TAB, this.VisitTab.REPORTS_DAILY_REPORT_TAB, this.VisitTab.REPORTS_FINAL_REPORT_TAB, this.VisitTab.REPORTS_CARRIER_TAB, this.VisitTab.REPORTS_PATIO_CLIENTE_TAB, this.VisitTab.REPORTS_VEHICLE_BIN_TAB, this.VisitTab.REPORTS_INACTIVE_TRUCK_WEIGHING_MOVEMENTS_TAB, this.VisitTab.REPORTS_PORT_SUMMARY_TAB]

        return types.includes(name);
    }

    function disabledTabState(TabUnique) {
        //Habilitados siempre, Detalles de Terminarl e Itinerario.
        switch (TabUnique) {
            case this.VisitTab.SCHEDULE_TAB:
            case this.VisitTab.TERMINAL_DETAILS_TAB:
            case this.VisitTab.ITINERARY_TAB:
                return false;
            default:
                return this.disabledTabStateIdVisitas;
        }
    }

    //computed
    function disabledTabStateIdVisitas() {
        return this.VisitId == '';
    }   
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    
    function checkIconToggle() {
        if (this.collapsed) {
            return "cil-chevron-right-alt";
        } else {
            return "cil-chevron-left-alt";
        }
    }
    
    function checkTextToggle() {
        if (this.collapsed) {
            return this.$t("label.close");
        } else {
            return this.$t("label.watch");
        }
    }

    function VisitTab() {
        return {
            GENERAL_INFORMATION_TAB: process.env.VUE_APP_VISIT_GENERAL_INFORMATION_TAB,
            ITINERARY_TAB: process.env.VUE_APP_VISIT_ITINERARY_TAB,
            SCHEDULE_TAB: process.env.VUE_APP_VISIT_SCHEDULE_TAB,
            TERMINAL_DETAILS_TAB: process.env.VUE_APP_VISIT_AGENDA_TERMINAL_DETAILS_TAB,
            CRANES_TAB: process.env.VUE_APP_VISIT_AGENDA_CRANES_TAB,
            BILL_OF_LADING_TAB: process.env.VUE_APP_VISIT_BILL_OF_LADING_TAB,
            DRAFT_INSPECTIONS_TAB: process.env.VUE_APP_VISIT_DRAFT_INSPECTIONS_TAB,
            DRAFT_INSPECTIONS_REGISTER_TAB: process.env.VUE_APP_VISIT_DRAFT_INSPECTIONS_REGISTER_TAB,
            DRAFT_TRANSPORT_CERTIFICATION_TAB: process.env.VUE_APP_VISIT_DRAFT_TRANSPORT_CERTIFICATION_TAB,
            DRAFT_YARD_CERTIFICATION_TAB: process.env.VUE_APP_VISIT_DRAFT_YARD_CERTIFICATION_TAB,
            GENERAL_CARGO_TRACKING_TAB: process.env.VUE_APP_VISIT_GENERAL_CARGO_TRACKING_TAB,
            GENERAL_CARGO_TRACKING_DISCHARGE_TAB: process.env.VUE_APP_VISIT_GENERAL_CARGO_TRACKING_DISCHARGE_TAB,
            GENERAL_CARGO_TRACKING_DISCHARGE_STOWAGE_TAB: process.env.VUE_APP_VISIT_GENERAL_CARGO_TRACKING_DISCHARGE_STOWAGE_TAB,
            GENERAL_CARGO_TRACKING_DISCHARGE_CONSIGNEE_IDENTIFICATION_TAB: process.env.VUE_APP_VISIT_GENERAL_CARGO_TRACKING_DISCHARGE_CONSIGNEE_IDENTIFICATION_TAB,
            GENERAL_CARGO_TRACKING_DISCHARGE_DIRECT_DISCHARGE_TAB: process.env.VUE_APP_VISIT_GENERAL_CARGO_TRACKING_DISCHARGE_DIRECT_DISCHARGE_TAB,
            GENERAL_CARGO_TRACKING_SHIPMENT_TAB: process.env.VUE_APP_VISIT_GENERAL_CARGO_TRACKING_SHIPMENT_TAB,
            GENERAL_CARGO_TRACKING_SHIPMENT_CONSIGNEE_IDENTIFICATION_TAB: process.env.VUE_APP_VISIT_GENERAL_CARGO_TRACKING_SHIPMENT_CONSIGNEE_IDENTIFICATION_TAB,
            GENERAL_CARGO_TRACKING_SHIPMENT_STOWAGE_TAB: process.env.VUE_APP_VISIT_GENERAL_CARGO_TRACKING_SHIPMENT_STOWAGE_TAB,
            CONTAINER_TAB: process.env.VUE_APP_VISIT_CONTAINER_TAB,
            CONTAINER_STOWAGE_TAB: process.env.VUE_APP_VISIT_CONTAINER_STOWAGE_TAB,
            CONTAINER_INSPECTIONS_TAB: process.env.VUE_APP_VISIT_CONTAINER_INSPECTIONS_TAB,
            SHIPMENT_ORDERS_TAB: process.env.VUE_APP_VISIT_SHIPMENT_ORDERS_TAB,
            SHIPMENT_ORDERS_ORDERS_LIST_TAB: process.env.VUE_APP_VISIT_SHIPMENT_ORDERS_ORDERS_LIST_TAB,
            SHIPMENT_ORDERS_INCIDENCE_TAB: process.env.VUE_APP_VISIT_SHIPMENT_ORDERS_INCIDENCE_TAB,
            SHIPMENT_ORDERS_WEIGHING_TAB: process.env.VUE_APP_VISIT_SHIPMENT_ORDERS_WEIGHING_TAB,
            RESOURCES_TAB: process.env.VUE_APP_VISIT_RESOURCES_TAB,
            RESOURCES_RESOURCES_TAB: process.env.VUE_APP_VISIT_RESOURCES_RESOURCES_TAB,
            RESOURCES_SUPPLIES_TAB: process.env.VUE_APP_VISIT_RESOURCES_SUPPLIES_TAB,
            RESOURCES_MACHINERIES_TAB: process.env.VUE_APP_VISIT_RESOURCES_MACHINERIES_TAB,
            INSPECTIONS_TAB: process.env.VUE_APP_VISIT_INSPECTIONS_TAB,
            TIMES_TAB: process.env.VUE_APP_VISIT_TIMES_TAB,
            TIMES_VESSEL_TAB: process.env.VUE_APP_VISIT_TIMES_VESSEL_TAB,
            TIMES_DETENTION_CRANE_TAB: process.env.VUE_APP_VISIT_TIMES_DETENTION_CRANE_TAB,
            ELECTRONIC_DATA_TAB: process.env.VUE_APP_VISIT_ELECTRONIC_DATA_TAB,
            ELECTRONIC_DATA_CURRENT_STATUS_OF_THE_VESSEL_TAB: process.env.VUE_APP_VISIT_ELECTRONIC_DATA_CURRENT_STATUS_OF_THE_VESSEL_TAB,
            ELECTRONIC_DATA_FULL_CARGO_PLAN_TAB: process.env.VUE_APP_VISIT_ELECTRONIC_DATA_FULL_CARGO_PLAN_TAB,
            ELECTRONIC_DATA_BAY_VIEW_TAB: process.env.VUE_APP_VISIT_ELECTRONIC_DATA_BAY_VIEW_TAB,
            ELECTRONIC_DATA_PRELIMINARY_CUTS_TAB: process.env.VUE_APP_VISIT_ELECTRONIC_DATA_PRELIMINARY_CUTS_TAB,
            ELECTRONIC_DATA_PLANE_ADJUSTMENTS_TAB: process.env.VUE_APP_VISIT_ELECTRONIC_DATA_PLANE_ADJUSTMENTS_TAB,
            REPORTS_TAB: process.env.VUE_APP_VISIT_REPORTS_TAB,
            REPORTS_SUPPLIES_TAB: process.env.VUE_APP_VISIT_REPORTS_SUPPLIES_TAB,
            REPORTS_RESOURCES_TAB: process.env.VUE_APP_VISIT_REPORTS_RESOURCES_TAB,
            REPORTS_MACHINERIES_TAB: process.env.VUE_APP_VISIT_REPORTS_MACHINERIES_TAB,
            REPORTS_TRANSACTIONS_CONTAINER_CHIP_TAB: process.env.VUE_APP_VISIT_REPORTS_TRANSACTIONS_CONTAINER_CHIP_TAB,
            REPORTS_TRANSACTIONS_GENERAL_CARGO_TAB: process.env.VUE_APP_VISIT_REPORTS_TRANSACTIONS_GENERAL_CARGO_TAB,
            REPORTS_TRANSACTIONS_BULK_CARRIER_TAB: process.env.VUE_APP_VISIT_REPORTS_TRANSACTIONS_BULK_CARRIER_TAB,
            REPORTS_IDLE_TIME_TAB: process.env.VUE_APP_VISIT_REPORTS_IDLE_TIME_TAB,
            REPORTS_ORDERS_LIST_TAB: process.env.VUE_APP_VISIT_REPORTS_ORDERS_LIST_TAB,
            REPORTS_ORDERS_BY_CARRIER_TAB: process.env.VUE_APP_VISIT_REPORTS_ORDERS_BY_CARRIER_TAB,
            REPORTS_ORDERS_BY_DRIVER_TAB: process.env.VUE_APP_VISIT_REPORTS_ORDERS_BY_DRIVER_TAB,
            REPORTS_ORDERS_BY_VEHICLE_TAB: process.env.VUE_APP_VISIT_REPORTS_ORDERS_BY_VEHICLE_TAB,
            REPORTS_ORDERS_BY_BIN_TAB: process.env.VUE_APP_VISIT_REPORTS_ORDERS_BY_BIN_TAB,
            REPORTS_ORDERS_BY_YARD_TAB: process.env.VUE_APP_VISIT_REPORTS_ORDERS_BY_YARD_TAB,
            REPORTS_ORDERS_WEIGHT_TAB: process.env.VUE_APP_VISIT_REPORTS_ORDERS_WEIGHT_TAB,
            REPORTS_ORDERS_NOT_WEIGHED_TAB: process.env.VUE_APP_VISIT_REPORTS_ORDERS_NOT_WEIGHED_TAB,
            REPORTS_ORDERS_BY_LAPSE_TAB: process.env.VUE_APP_VISIT_REPORTS_ORDERS_BY_LAPSE_TAB,
            REPORTS_TRUCK_WEIGHING_SCALE_MOVEMENTS_TAB: process.env.VUE_APP_VISIT_REPORTS_TRUCK_WEIGHING_SCALE_MOVEMENTS_TAB,
            REPORTS_INACTIVE_TRUCK_WEIGHING_MOVEMENTS_TAB: process.env.VUE_APP_VISIT_REPORTS_INACTIVE_TRUCK_WEIGHING_MOVEMENTS_TAB,
            REPORTS_BINS_RETURNED_TAB: process.env.VUE_APP_VISIT_REPORTS_BINS_RETURNED_TAB,
            REPORTS_OPERATIONAL_REPORT_TAB: process.env.VUE_APP_VISIT_REPORTS_OPERATIONAL_REPORT_TAB,
            REPORTS_LISTS_TAB: process.env.VUE_APP_VISIT_REPORTS_LISTS_TAB,
            REPORTS_CONTAINERS_GENERAL_TAB: process.env.VUE_APP_VISIT_REPORTS_CONTAINERS_GENERAL_TAB,
            REPORTS_DANGEROUS_CARGO_TAB: process.env.VUE_APP_VISIT_REPORTS_DANGEROUS_CARGO_TAB,
            REPORTS_REEFER_CONTAINERS_TAB: process.env.VUE_APP_VISIT_REPORTS_REEFER_CONTAINERS_TAB,
            REPORTS_OOG_CARGO_TAB: process.env.VUE_APP_VISIT_REPORTS_OOG_CARGO_TAB,
            REPORTS_RESTOW_TAB: process.env.VUE_APP_VISIT_REPORTS_RESTOW_TAB,
            REPORTS_LOAD_TAB: process.env.VUE_APP_VISIT_REPORTS_LOAD_TAB,
            REPORTS_CANCELED_TAB: process.env.VUE_APP_VISIT_REPORTS_CANCELED_TAB,
            REPORTS_DISCHARGE_TAB: process.env.VUE_APP_VISIT_REPORTS_DISCHARGE_TAB,
            REPORTS_VGM_DETAIL_TAB: process.env.VUE_APP_VISIT_REPORTS_VGM_DETAIL_TAB,
            REPORTS_SUMARIES_TAB: process.env.VUE_APP_VISIT_REPORTS_SUMARIES_TAB,
            REPORTS_TYPE_STATUS_CONTAINERS_TAB: process.env.VUE_APP_VISIT_REPORTS_TYPE_STATUS_CONTAINERS_TAB,
            REPORTS_TYPE_CONTAINERS_TAB: process.env.VUE_APP_VISIT_REPORTS_TYPE_CONTAINERS_TAB,
            REPORTS_PORT_LINE_CONTAINERS_TAB: process.env.VUE_APP_VISIT_REPORTS_PORT_LINE_CONTAINERS_TAB,
            REPORTS_DANGEROUS_CARGO_TAB: process.env.VUE_APP_VISIT_REPORTS_DANGEROUS_CARGO_TAB,
            REPORTS_TYPE_BY_CRANE_TAB: process.env.VUE_APP_VISIT_REPORTS_TYPE_BY_CRANE_TAB,
            REPORTS_VESSEL_ACTIVITY_RECAP_TAB: process.env.VUE_APP_VISIT_REPORTS_VESSEL_ACTIVITY_RECAP_TAB,
            REPORTS_PORT_SUMMARY_TAB: process.env.VUE_APP_VISIT_REPORTS_PORT_SUMMARY_TAB,
            REPORTS_FINAL_OPERATIONS_REPORT_TAB: process.env.VUE_APP_VISIT_REPORTS_FINAL_OPERATIONS_REPORT_TAB,
            REPORTS_DAILY_REPORT_TAB: process.env.VUE_APP_VISIT_REPORTS_DAILY_REPORT_TAB,
            REPORTS_FINAL_REPORT_TAB: process.env.VUE_APP_VISIT_REPORTS_FINAL_REPORT_TAB,
            REPORTS_CARRIER_TAB: process.env.VUE_APP_VISIT_REPORTS_CARRIER_TAB,
            REPORTS_PATIO_CLIENTE_TAB: process.env.VUE_APP_VISIT_REPORTS_PATIO_CLIENTE_TAB,
            REPORTS_VEHICLE_BIN_TAB: process.env.VUE_APP_VISIT_REPORTS_VEHICLE_BIN_TAB,
            REPORTS_TDR_TAB: process.env.VUE_APP_VISIT_REPORTS_TDR_TAB,
            REPORTS_TDR_GENERAL_TAB: process.env.VUE_APP_VISIT_REPORTS_TDR_GENERAL_TAB,
            REPORTS_TDR_WITH_DISCOUNTS_TAB: process.env.VUE_APP_VISIT_REPORTS_TDR_WITH_DISCOUNTS_TAB,
            REPORTS_TRANSACTION_REVERSALS_TAB: process.env.VUE_APP_VISIT_REPORTS_TRANSACTION_REVERSALS_TAB,
            REPORTS_INSPECTIONS_DONE_TAB: process.env.VUE_APP_VISIT_REPORTS_INSPECTIONS_DONE_TAB,
            REPORTS_WEIGHING_REPORT_TAB: process.env.VUE_APP_VISIT_REPORTS_WEIGHING_REPORT_TAB,
            REPORTS_DELAY_TIMES_STRATEGIC_ALLIANCE_TAB: process.env.VUE_APP_VISIT_REPORTS_DELAY_TIMES_STRATEGIC_ALLIANCE_TAB,
            REPORTS_LAPSE_DETAIL_GENERAL_CARGO_TAB: process.env.VUE_APP_VISIT_REPORTS_LAPSE_DETAIL_GENERAL_CARGO_TAB,
            REPORTS_LAPSE_SUMMARY_GENERAL_CARGO_TAB: process.env.VUE_APP_VISIT_REPORTS_LAPSE_SUMMARY_GENERAL_CARGO_TAB,
            REPORTS_ORDERS_LIST_GENERAL_CARGO_TAB: process.env.VUE_APP_VISIT_REPORTS_ORDERS_LIST_GENERAL_CARGO_TAB,
            END_OF_OPERATIONS_TAB: process.env.VUE_APP_VISIT_END_OF_OPERATIONS_TAB,
            ERRORS_LOGS_TAB: process.env.VUE_APP_VISIT_ERRORS_LOGS_TAB,
        }
    }
    function IsGeneralCargoVessel() {
        return this.TpVesselId.toUpperCase() == process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID;
    }
    //watch
    function collapseVisits(newValue){
        alert(newValue);
        if(newValue){
            
        }
    }

    export default {
        name:'menu-visita',
        mixins: [TdrExcel, ReportesVisitas, IdleTime, Timeslost_StrategicAlliance],
        data,
        mounted:mounted,
        methods:{
            formaterNormalDate,
            handleTab,
            imageSrc,
            closeCollapse,
            activeItem,
            clickItem,
            activeItemReport,
            clickItemReport,
            checkSideBarToggle,
            newTdr,
            activeItemElectronic,
            clickItemElectronic,
            newIdleTime,
            newTimeslost_StrategicAlliance,
            newOperationsSummary,
            newOperationalReport,
            activeItemOrder,
            clickItemOrder,
            activeItemDratf,
            clickItemDratf,
            newFinalOperationsReport,
            activeItemTime,
            clickItemTime,
            getMenu,
            checkActiveItemByGpoModuleNameEn,
            setActiveItem,
            titleLocalize,
            checkReportType,
            disabledTabState,
        },
        components: {
            CustomTabs,
            CustomTab,
            appItinerario,
            appGeneral,
            ListDraft,
            DraftTransport,
            DraftYard,
            ManifiestoCarga,
            appAgenda,
            appReporte,
            appConsultReporte,
            FullCargoView,
            BayView,
            appResource,
            appTransacciones,
            appTransactionsGeneralCargo,
            TransactionsTablets,
            appInsumos,
            appRecursos,
            appBaplie,
            appDelay,
            appInspeccion,
            appManual,
            appOrderList,
            WeighingList,
            appIncidenceList,
            appDetentionCrane,
            ErrorLog,
            EndOperations,
            MachineryReport,
            appStowageList,
            appAssignmentList,
            DirectDischarge,
            appStowageContainerList,
            appAssignmentShipmentList,
            appStowageShipmentList,
            appLapseDetail,
            appLapseSummary,
            OrderListGeneralCargo,
        },
        computed:{
            textEspecial,
            disabledTabStateIdVisitas,
            apiStateLoading,
            apiStateFormLoading,
            checkTextToggle,
            checkIconToggle,
            VisitTab,
            IsGeneralCargoVessel,
            textItinerario(){
                if(this.collapseVisits){
                    let itinerarySelected = this.itinerarySelected;
                    return `${itinerarySelected.VesselName}, ${itinerarySelected.Voyage}, ${itinerarySelected.LabelArrival} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Arrival)} - ${itinerarySelected.LabelDeparture} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Departure)}`
                }else{
                    return "";
                }
            },
            ...mapState({
                dropItemReport: state => state.visitas.dropItemReport,
                dropItemOrder: state => state.visitas.dropItemOrder,
                dropItemDratf: state => state.visitas.dropItemDratf,
                dropItemTime: state => state.visitas.dropItemTime,
                messageError : state=> state.visitas.messageError,
                apiState: state => state.visitas.apiState,
                apiStateForm: state => state.visitas.apiStateForm,
                branch: state => state.auth.branch,
                VisitId: state => state.visitas.VisitId,
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                collapseVisits: state => state.visitas.collapseVisits,
                dropItemAgenda: state => state.visitas.dropItemAgenda,
                dropItemElectronic: state => state.visitas.dropItemElectronic,
                bulkCarrier: state => state.visitas.bulkCarrier,
                FgGeneralCargo: state => state.visitas.FgGeneralCargo,
                role: (state) => state.auth.role,
                TpVesselId: state => state.visitas.itinerarySelected.TpVesselId
            })
        },
        beforeDestroy(){
            this.closeCollapse();
        },
        watch:{
            collapseVisits
        }
    }
</script>

<style lang="scss" >
    @import 'css/style.scss';
    .dropdowntab > .custom-tab-link{
        padding:0px;
    }   
    .custom-nav li > .custom-tab-link{
        .dropdown{
            width:100%;
            padding: 0 20px;
            .dropdown-toggle {
                color:white;
                opacity: 1;
                padding-left: 0;
                padding-top: 0;

            }
            .dropdown-toggle::after {
                margin-left: 0.255em;
                margin-right: 1.0em;
            }
        }
    }
    .custom-nav li.active > .custom-tab-link {
        .dropdown{
            width:100%;
            padding: 0 20px;
            .dropdown-toggle {
                color: #000;
                opacity: 1;
            }
            .dropdown-toggle::after {
                margin-left: 0.255em;
                margin-right: 1.0em;
            }
        }
    } 
    .iconTab{
        max-width: 100%;
        height: auto;
        border-radius: 0.25rem !important;
        margin-left: 1.5rem !important;
        margin-top: -0.4rem !important;
    }     
</style>