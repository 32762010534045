import { DateFormater } from '@/_helpers/funciones';
import {saveAs} from "file-saver";
import Excel from "exceljs/dist/es5/exceljs.browser";
import { mapState } from 'vuex';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';

function numeroLetra(valor) {
    const abc = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"];

    return abc[valor - 1];
}

async function getTimeslost_StrategicAlliance(items) {
    let _this = this.$i18n.locale;
    const workbook = new Excel.Workbook();
    const documento = this.$t('label.Timeslost')+' - '+this.$t('label.StrategicAlliance');

    //PRIMERA HOJA
    var titulo = this.$t('label.detail');
    const worksheet = workbook.addWorksheet(titulo);

        //INFO BUQUE
    worksheet.mergeCells('A1:L1');
    const response1 = await this.$http.getFile('base64', {
        path:this.user.LogoRoute.replace('Public/','', null, 'i')
    });
    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;
    const imageId = workbook.addImage({
        base64: myBase64Image,
        extension: divisiones1[1],
    });
    worksheet.addImage(imageId, 'A1:L1');
    worksheet.getRow(1).height = 80;

    worksheet.mergeCells('A2:L2');
    const customCell1 = worksheet.getCell("A2");
    customCell1.height = 50;
    customCell1.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };

    worksheet.mergeCells('A3:L3');
    const customCell2 = worksheet.getCell("A3");
    customCell2.height = 50;
    customCell2.value = this.$t('label.vesselName')+': ';
    customCell2.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };

    worksheet.mergeCells("A4:L4");
    const customCell3 = worksheet.getCell("A4");
    customCell3.height = 50;
    customCell3.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCell3.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

    worksheet.mergeCells("A5:L5");
    const customCell4 = worksheet.getCell("A5");
    customCell4.height = 50;
    customCell4.value = this.$t('label.Timeslost');
    customCell4.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };

    //SEGUNDA HOJA
    titulo = this.$t('label.detail')+1;
    const worksheet2 = workbook.addWorksheet(titulo);

        //INFO BUQUE
    worksheet2.mergeCells('A1:L1');
    worksheet2.addImage(imageId, 'A1:J1');
    worksheet2.getRow(1).height = 80;

    worksheet2.mergeCells('A2:L2');
    const customCell5 = worksheet2.getCell('A2');
    customCell5.height = 50;
    customCell5.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCell5.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCell5.value = '';

    worksheet2.mergeCells('A3:L3');
    const customCell6 = worksheet2.getCell('A3');
    customCell6.height = 50;
    customCell6.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCell6.value = this.$t('label.Timeslost');

    worksheet2.mergeCells('C4:C4');
    const customCell7 = worksheet2.getCell('C4');
    customCell7.height = 50;
    worksheet2.getColumn('C').width = 30;
    customCell7.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCell7.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCell7.value = this.$t('label.vesselName')+': ';

    worksheet2.mergeCells('F4:F4');
    const customCell8 = worksheet2.getCell('F4');
    customCell8.height = 50;
    customCell8.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };
    customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCell8.value = this.$t('label.voyage')+': ';




    let fileName = documento;
    let today = DateFormater.formatOnlyDateWithSlash(new Date());
    today = today.replaceAll("/", "");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    workbook.xlsx.writeBuffer()
        .then(buffer => {
            saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: 'Reporte generado exitosamente',
                    type: "success"
                });
            }) 
}

export default {
    methods: {
      getTimeslost_StrategicAlliance,
      numeroLetra,
    },
    computed:{
      ...mapState({
          Visit: state => state.visitas.VisitId,
          dropItemReport: state => state.visitas.dropItemReport,       
          ItineraryId: state => state.visitas.ItineraryId,
          itinerarySelected: state => state.visitas.itinerarySelected,
          user: state => state.auth.user,
          branch: state => state.auth.branch,
          bulkCarrier: state => state.visitas.bulkCarrier
      }),
    },
}