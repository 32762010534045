<template>
  <div class="container-fluid">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CRow v-if="generar">
      <CCol sm="12">
        <generar-baplie :valor="generar" @child-refresh="refrescarTabla=true" />
      </CCol>
    </CRow>
    <CRow v-if="!generar">
      <CCol sm="6" class="bg-watch d-flex align-items-center form-inline py-2">
        <label>
          {{$t('label.name')}}
        </label>
        <input class="form-control w-50 ml-2 oscuro" disabled v-model="name" />
      </CCol>
      <CCol sm="6" class="bg-watch d-flex align-items-center form-inline">
        <label>
          {{$t('label.documentType')}}
        </label>
        <input class="form-control w-50 ml-2 oscuro" disabled v-model="documentType" />
      </CCol>
      <CCol sm="12" class="text-center mt-2">
        <h3>
            {{$t('label.bapliesList')}}
        </h3>
      </CCol>
      <CCol sm="12" class="text-right mb-2">
        <CButton
          square
          color="add"
          v-c-tooltip="{
            content: ' BAPLIE',
            placement: 'top',
          }"
          @click="generar=true"
        >
          <CIcon name="cil-playlist-add" /><span class="ml-1"
            >{{$t('label.nuevo')}}</span
          >
        </CButton>
      </CCol>
      <CCol sm="12">
        <dataTableExtended
     class="align-center-row-datatable"
          size="lg"
          :fields="fields"
          :items="items"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          :loading="loadingTable"
          hover
           
        >
          <template #loading>
            <loading/>
          </template>
          <template #Nro="{item}">
            <td class="center-cell">
                {{ item.Nro }}
            </td>
          </template>
          <template #EdiFileName="{item}">
            <td>
                {{ item.EdiFileName }}
            </td>
          </template>
          <template #EdiVersion="{item}">
            <td>
                {{ item.EdiVersion }}
            </td>
          </template>
          <template #Usuario="{item}">
            <td>
                {{ item.Usuario }}
            </td>
          </template>
          <template #Fecha="{item}">
            <td class="center-cell">
                {{ item.Fecha }}
            </td>
          </template>
          <template #Status="{item}">
              <td class="center-cell">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
              </td>
            </template>
          <template #Detalle="{ item }">
            <td class="center-cell">
                <CButton
                  size="sm"
                  color="excel"
                  v-c-tooltip="{
                    content: $t('label.download')+' BAPLIE',
                    placement: 'top',
                  }"
                  @click="descargarArchivo(item)"
                >
                  <CIcon name="cil-cloud-download" />
                </CButton>
                <CButton
                  size="sm"
                  color="wipe"
                  class="mx-1"
                  v-c-tooltip="{
                    content: $t('label.delete')+' BAPLIE',
                    placement: 'left',
                  }"
                  @click="ConfirmarEliminado(item)"
                >
                  <CIcon name="cil-trash" />
                </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { DateFormater, alertPropertiesHelpers, tableTextTranslatedHelpers } from '@/_helpers/funciones';
import General from '@/_mixins/general';
import GenerarBaplie from './generar-baplie';
import { mapState } from 'vuex';

function data() {
  return {
    items: [],
    loadingTable: false,
    loadingOverlay: false,
    showModal: false,
    refrescarTabla: false,
    generar: false,
    VisitId: '',
    documentType: '',
    name: '',
  };
}

function fields(){
  return [
    { key: 'Nro', label: '#', filter: false , _style: 'width:3%; text-align:center;', },
    { key: 'EdiFileName', label:this.$t('label.document')+" BAPLIE", },
    { key: 'EdiVersion', label: 'EDI VERSION', },
    { key: 'Usuario', label:this.$t('label.user'), },
    { key: 'Fecha', label:this.$t('label.date'), _style: 'text-align:center;' },
    { key: 'Status', label:this.$t('label.status'), _style: 'text-align:center;' },
    { key: 'Detalle', label: '', sorter: false, filter: false, _style: 'min-width: 100px; width:8%' }
  ];
}

function tableText(){
    return tableTextTranslatedHelpers(this);
}

function ConfirmarEliminado(item) {
  this.$swal.fire(
      alertPropertiesHelpers(this, {
        text:  `${this.$t('label.changeStatusQuestion')} ${item.Nro}?`,
      })
    ).then((result) => {
    if (result.isConfirmed) {
      this.loadingOverlay = true;
      let res = [];
      let EdiJson = [];

      EdiJson = {
        EdiFileGenerateId: item.EdiFileGenerateId
      };

      this.$http.put("EdiFileGeneration-Deactivate", EdiJson, { root: 'EdiJson' })
      .then(response => {
        res = [...response.data.data];
        this.$notify({
          group: 'container',
          title: '¡Exito!',
          text: res[0].Response,
          type: "success"
        });
        this.EdiFileList();
        this.loadingOverlay= false;    
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.loadingOverlay= false;
      });
    }
  });  
}

async function EdiFileList () {
  this.loadingOverlay = true;
  this.items = [];
  let i = 1;
  
  let listado = Array;
  await this.$http.get("EdiFileGeneration-list-by-VisitId", { VisitId: this.Visit }) //this.EdiFileId  '1e2d8901-9e5a-49e1-a15e-cfae8a8113bb'
  .then(response => {
    listado = [...response.data.data];

    this.items = listado.map(listado => Object.assign({}, this.items, {
      Nro: i++,
      EdiFileName: listado.EdiFileName,
      EdiVersion: listado.Version ? listado.Version : 'N/A',
      Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
      Status: listado.FgActEdiFileGenerate ? "ACTIVO" : 'INACTIVO',
      Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
      EdiFileGenerateId: listado.EdiFileGenerateId,
      EdiFileId: listado.EdiFileId,
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

async function descargarArchivo (item) {
  this.loadingOverlay = true;
  
  await this.$http.get("EdiFileContent-by-EdiFileGenerateId", { EdiFileGenerateId: item.EdiFileGenerateId }) //this.EdiFileId
  .then(response => {
    let listado = [...response.data.data];

    if (listado.length > 0) this.archivoDescargable(item.EdiFileName, listado[0].EdiFileText);
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

export default {
  name: 'listado-baplie',
  data,
  props: ['value'],
  mixins: [General],
  components: {
    GenerarBaplie
  },
  methods: {
    EdiFileList,
    ConfirmarEliminado,
    descargarArchivo
  },  
  watch: {
    refrescarTabla: function () {
      if (this.refrescarTabla) {
        this.generar = false;
        this.EdiFileList();
        this.refrescarTabla = false;
      }
    },
    dropItemElectronic: function (newValue) {
      if ((newValue==37) && (this.Visit!="")) {
        this.name = this.ediFileInfo.EdiFileName;
        this.documentType = this.ediFileInfo.TpEdiName;
        this.generar = false;
        this.refrescarTabla = true;
      }
    },
  },
  computed: {
    fields,
    tableText,
    ...mapState({
        Visit: state => state.visitas.VisitId,
        dropItemElectronic: state => state.visitas.dropItemElectronic,
        ediFileInfo: state => state.visitas.EdiFileInfo,
        EdiFileId: state => state.visitas.EdiFileId,
        EdiFileGenerateId: state => state.visitas.EdiFileGenerateId,
    })
  },
};
</script>
<style lang="scss">
  .oscuro {
    color: #000;
  }

  .top-menos {
    margin-top: -20px;
  }

  .bg-watch {
    background-color: #1E3A61;
    color: #fff;
  }

  .badge-danger {
    background-color: transparent;
    color: #e55353;
  }

  .center-cell {
    text-align: center;
  }

  .table-index {
    table {
      td {
        vertical-align: middle !important;
      }
    }
  }
</style>
</style>
