//var Excel = require('exceljs');

import axios from 'axios';
import {saveAs} from "file-saver";
import Excel from "exceljs/dist/es5/exceljs.browser";
import { mapState } from 'vuex';
import { isUndefined } from 'lodash';
import { DateFormater } from '@/_helpers/funciones';
import DataStorage from '@/_helpers/localStorage';

async function getTdrExcel (value,items) {
  this.loadingOverlay = true;

  const documento = 'TDR '+items[0].TerminalDetailsJson[0].VesselName+' '+ items[0].TerminalDetailsJson[0].VoyageArrival+' '+this.user.CompanyName;

  const response1 = await this.$http.getFile('base64', {
    path:this.user.LogoRoute.replace('Public/','', null, 'i')
  });
  const divisiones1 = this.user.LogoRoute.split(".");
  const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;

  //TODOS SON TIPO FECHA
  /*1) TERMINAL DETAILS */
  let callTimeDate = items[0].TerminalDetailsJson[0].CallTimeDate ? DateFormater.formatDateTimeWithSlash( items[0].TerminalDetailsJson[0].CallTimeDate) : '';
  let RouteTdrConfig =  items[0].TerminalDetailsJson[0].RouteTdrConfig;

  /*2) SCHEDULE DETAILS */ 
  const arrivedRoad = items[0].ScheduleDetailsJson[0].ArrivedRoad ? DateFormater.formatDateTimeWithSlash( items[0].ScheduleDetailsJson[0].ArrivedRoad) : '';
  const pilotOnBoard = items[0].ScheduleDetailsJson[0].PilotOnBoard ? DateFormater.formatDateTimeWithSlash( items[0].ScheduleDetailsJson[0].PilotOnBoard) : '';
  const arrivalBerth = items[0].ScheduleDetailsJson[0].ArrivalBerth ? DateFormater.formatDateTimeWithSlash( items[0].ScheduleDetailsJson[0].ArrivalBerth) : '';
  const ladderSave = items[0].ScheduleDetailsJson[0].LadderSave ? DateFormater.formatDateTimeWithSlash( items[0].ScheduleDetailsJson[0].LadderSave) : '';
  const visitOn = items[0].ScheduleDetailsJson[0].VisitOn ? DateFormater.formatDateTimeWithSlash( items[0].ScheduleDetailsJson[0].VisitOn) : '';
  const visitOff = items[0].ScheduleDetailsJson[0].VisitOff ? DateFormater.formatDateTimeWithSlash( items[0].ScheduleDetailsJson[0].VisitOff) : '';
  const ispsIdInspection = items[0].ScheduleDetailsJson[0].IspsIdInspection ? DateFormater.formatDateTimeWithSlash( items[0].ScheduleDetailsJson[0].IspsIdInspection) : '';
  const lashingGangsOn = items[0].ScheduleDetailsJson[0].LashingGangsOn ? DateFormater.formatDateTimeWithSlash( items[0].ScheduleDetailsJson[0].LashingGangsOn) : '';
  const firstCraneLift = items[0].ScheduleDetailsJson[0].FirstCraneLift ? DateFormater.formatDateTimeWithSlash( items[0].ScheduleDetailsJson[0].FirstCraneLift) : '';
  const lastCraneLift = items[0].ScheduleDetailsJson[0].LastCraneLift ? DateFormater.formatDateTimeWithSlash( items[0].ScheduleDetailsJson[0].LastCraneLift) : '';
  const lashingGangsOff = items[0].ScheduleDetailsJson[0].LashingGangsOff ? DateFormater.formatDateTimeWithSlash( items[0].ScheduleDetailsJson[0].LashingGangsOff) : '';
  const unberthed = items[0].ScheduleDetailsJson[0].Unberthed ? DateFormater.formatDateTimeWithSlash( items[0].ScheduleDetailsJson[0].Unberthed) : ''; 

  let issued = this.$store.state.auth.user.UserLastName+' '+this.$store.state.auth.user.UserName;

  let workbook = new Excel.Workbook();

  //var url = "http://localhost:8080/excel/TDR_FORMATO2.xlsx";
  var url = `${DataStorage.getConnection()}`+RouteTdrConfig;
  //var url = "http://pruebassegops.softicompany.com/TDR_FORMATO.xlsx";

  // Read the binary excel file, refer to https://github.com/SheetJS/js-xlsx#utility-functions
  //axios.get(url, {responseType:'arraybuffer'})
  //.then((res) => {
  function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const rsExcel = await this.$http.getFile('base64', {
    path: RouteTdrConfig
  });

  // Read the binary excel file, refer to https://github.com/SheetJS/js-xlsx#utility-functions
  //axios.get(url, {responseType:'arraybuffer'})
  //.then((res) => {
  //var data = new Uint8Array(res.data)
  var data = _base64ToArrayBuffer(rsExcel.data.data.base64);
  //var data = new Uint8Array(res.data)
  /*var wb = XLSX.read(data, {type:"array"})
  var sheets = wb.Sheets;*/
  workbook.xlsx.load(data).then((wb)=> {
  workbook.calcProperties.fullCalcOnLoad = true;
  var worksheet1 = workbook.getWorksheet('Equipment Recap');
  var worksheet2 = workbook.getWorksheet('Productivity');
  var worksheet3 = workbook.getWorksheet('Restow');
  var worksheet4 = workbook.getWorksheet('Imo');
  var worksheet5 = workbook.getWorksheet('OOG');
  var worksheet6 = workbook.getWorksheet('Others Terminal');
  var worksheet8 = workbook.getWorksheet('Over Time');

  //logo
  const imageId = workbook.addImage({
      base64: myBase64Image,
      extension: divisiones1[1],
  });

  worksheet1.addImage(imageId, 'A1:C4');
  worksheet2.addImage(imageId, {
    tl: { col: 0, row: 0 },
    br: { col: 2, row: 6 }
  });

  worksheet3.addImage(imageId, {
    tl: { col: 0, row: 0 },
    br: { col: 2.5, row: 5 }
  });

  worksheet4.addImage(imageId, 'A1:C5');
  worksheet5.addImage(imageId, 'A1:C5');
  worksheet6.addImage(imageId, 'A1:B5');
  //worksheet7.addImage(imageId, 'A1:B5');

  /*1) TERMINAL DETAILS */
  if( items[0].TerminalDetailsJson[0]!='' ||  items[0].TerminalDetailsJson[0]!=null){
    worksheet1.getCell("F3").value =  items[0].TerminalDetailsJson[0].ServiceCode;
    worksheet1.getCell("F4").value =  items[0].TerminalDetailsJson[0].PortCode;
    worksheet1.getCell("F5").value = items[0].TerminalDetailsJson[0].TerminalName;
    worksheet1.getCell("F6").value = items[0].TerminalDetailsJson[0].VesselName;
    worksheet1.getCell("F7").value = items[0].TerminalDetailsJson[0].VoyageDeparture;
    worksheet1.getCell("F8").value =issued; 
    worksheet1.getCell("F9").value =callTimeDate;
    worksheet1.getCell("F10").value = items[0].TerminalDetailsJson[0].Operator;
    worksheet1.getCell("F11").value = items[0].TerminalDetailsJson[0].Length; 
    worksheet1.getCell("I11").value = items[0].TerminalDetailsJson[0].BerthName;
  }

  /*2) SCHEDULE DETAILS */ //TODOS SON TIPO FECHA
  worksheet1.getCell("G14").value = arrivedRoad;
  worksheet1.getCell("G15").value = pilotOnBoard;
  worksheet1.getCell("G16").value = arrivalBerth;
  worksheet1.getCell("G17").value = ladderSave;
  worksheet1.getCell("G18").value = visitOn;
  worksheet1.getCell("G19").value = visitOff;
  worksheet1.getCell("G20").value = ispsIdInspection;
  worksheet1.getCell("G21").value = lashingGangsOn;
  worksheet1.getCell("G22").value = firstCraneLift;
  worksheet1.getCell("G23").value = lastCraneLift;
  worksheet1.getCell("G24").value = lashingGangsOff;
  worksheet1.getCell("G25").value = unberthed;

  /*3) DISCHARGE */
  if(items[0].DischargeJson != ''){
    let k=0; 
    let longitudDischarge =  items[0].DischargeJson.length;

    for (let  i = 0; i < longitudDischarge; i++) { 
      let pos_C20=5+k;
      let pos_C20RF=6+k;
      let pos_C40=7+k;
      let pos_C40HC=8+k;
      let pos_C40RF=9+k;
      let pos_C45=10+k;

      worksheet1.getCell('P'+pos_C20).value =  items[0].DischargeJson[i].ShippingLineAbbrev;

      worksheet1.getCell('R'+pos_C20).value =  items[0].DischargeJson[i].C20_FULL;
      worksheet1.getCell('S'+pos_C20).value =  items[0].DischargeJson[i].C20_EMPTY;
      worksheet1.getCell('T'+pos_C20).value =  items[0].DischargeJson[i].C20_TRANS;
      worksheet1.getCell('W'+pos_C20).value =  items[0].DischargeJson[i].C20_EMPTY_TON;
      worksheet1.getCell('V'+pos_C20).value =  items[0].DischargeJson[i].C20_FULL_TON;

      worksheet1.getCell('R'+pos_C20RF).value =  items[0].DischargeJson[i].C20RF_FULL;
      worksheet1.getCell('S'+pos_C20RF).value =  items[0].DischargeJson[i].C20RF_EMPTY;
      worksheet1.getCell('T'+pos_C20RF).value =  items[0].DischargeJson[i].C20RF_TRANS;
      worksheet1.getCell('W'+pos_C20RF).value =  items[0].DischargeJson[i].C20RF_EMPTY_TON;
      worksheet1.getCell('V'+pos_C20RF).value =  items[0].DischargeJson[i].C20RF_FULL_TON;

      worksheet1.getCell('R'+pos_C40).value =  items[0].DischargeJson[i].C40_FULL;
      worksheet1.getCell('S'+pos_C40).value =  items[0].DischargeJson[i].C40_EMPTY;
      worksheet1.getCell('T'+pos_C40).value =  items[0].DischargeJson[i].C40_TRANS;
      worksheet1.getCell('W'+pos_C40).value =  items[0].DischargeJson[i].C40_EMPTY_TON;
      worksheet1.getCell('V'+pos_C40).value =  items[0].DischargeJson[i].C40_FULL_TON;

      worksheet1.getCell('R'+pos_C40HC).value =  items[0].DischargeJson[i].C40HC_FULL;
      worksheet1.getCell('S'+pos_C40HC).value =  items[0].DischargeJson[i].C40HC_EMPTY;
      worksheet1.getCell('T'+pos_C40HC).value =  items[0].DischargeJson[i].C40HC_TRANS;
      worksheet1.getCell('W'+pos_C40HC).value =  items[0].DischargeJson[i].C40HC_EMPTY_TON;
      worksheet1.getCell('V'+pos_C40HC).value =  items[0].DischargeJson[i].C40HC_FULL_TON;
    
      worksheet1.getCell('R'+pos_C40RF).value =  items[0].DischargeJson[i].C40RF_FULL;
      worksheet1.getCell('S'+pos_C40RF).value =  items[0].DischargeJson[i].C40RF_EMPTY;
      worksheet1.getCell('T'+pos_C40RF).value =  items[0].DischargeJson[i].C40RF_TRANS;
      worksheet1.getCell('W'+pos_C40RF).value =  items[0].DischargeJson[i].C40RF_EMPTY_TON;
      worksheet1.getCell('V'+pos_C40RF).value =  items[0].DischargeJson[i].C40RF_FULL_TON;

      worksheet1.getCell('R'+pos_C45).value =  items[0].DischargeJson[i].C45_FULL;
      worksheet1.getCell('S'+pos_C45).value =  items[0].DischargeJson[i].C45_EMPTY;
      worksheet1.getCell('T'+pos_C45).value =  items[0].DischargeJson[i].C45_TRANS;
      worksheet1.getCell('W'+pos_C45).value =  items[0].DischargeJson[i].C45_EMPTY_TON;
      worksheet1.getCell('V'+pos_C45).value =  items[0].DischargeJson[i].C45_FULL_TON;

      k=k+6;
    }
  }

  /*4) RESTOWED */
  if(items[0].RestowJson != ''){  
    let j=0; 
    let longitudRestow =  items[0].RestowJson.length;
  
    for (let  i = 0; i < longitudRestow; i++) {   
      let pos_BB_C20=5+j;
      let pos_BB_C40=6+j;
      let pos_BB_C45=7+j;
      let pos_BSB_C20=8+j;
      let pos_BSB_C40=9+j;
      let pos_BSB_C45=10+j;

      worksheet1.getCell('AC'+pos_BB_C20).value =  items[0].RestowJson[i].BB_C20_FULL;
      worksheet1.getCell('AD'+pos_BB_C20).value =  items[0].RestowJson[i].BB_C20_EMPTY;

      worksheet1.getCell('AC'+pos_BB_C40).value =  items[0].RestowJson[i].BB_C40_FULL;
      worksheet1.getCell('AD'+pos_BB_C40).value =  items[0].RestowJson[i].BB_C40_EMPTY;

      worksheet1.getCell('AC'+pos_BB_C45).value =  items[0].RestowJson[i].BB_C45_FULL;
      worksheet1.getCell('AD'+pos_BB_C45).value =  items[0].RestowJson[i].BB_C45_EMPTY;

      worksheet1.getCell('AC'+pos_BSB_C20).value =  items[0].RestowJson[i].BSB_C20_FULL;
      worksheet1.getCell('AD'+pos_BSB_C20).value =  items[0].RestowJson[i].BSB_C20_EMPTY;

      worksheet1.getCell('AC'+pos_BSB_C40).value =  items[0].RestowJson[i].BSB_C40_FULL;
      worksheet1.getCell('AD'+pos_BSB_C40).value =  items[0].RestowJson[i].BSB_C40_EMPTY;
      
      worksheet1.getCell('AC'+pos_BSB_C45).value =  items[0].RestowJson[i].BSB_C45_FULL;
      worksheet1.getCell('AD'+pos_BSB_C45).value =  items[0].RestowJson[i].BSB_C45_EMPTY; 

      j=j+6;
    }
  }

  /*5) HATCH COVER MOVES AND TWISTSLOCK BOX*/
  if(items[0].HatchCoverTwinBoxJson != ''){ 
    let longitudHatchCover =  items[0].HatchCoverTwinBoxJson.length;
    let letraValorHatchCover=3;

    for (let  i = 0; i < longitudHatchCover; i++) { 
      worksheet1.getRow(28).getCell(letraValorHatchCover).value =  items[0].HatchCoverTwinBoxJson[i].HatchCoverOn;
      worksheet1.getRow(29).getCell(letraValorHatchCover).value =  items[0].HatchCoverTwinBoxJson[i].HatchCoverOff;
      worksheet1.getRow(30).getCell(letraValorHatchCover).value =  items[0].HatchCoverTwinBoxJson[i].TwinsBox;
      worksheet1.getRow(31).getCell(letraValorHatchCover).value =  items[0].HatchCoverTwinBoxJson[i].RestowVisibilityOpr;
      worksheet1.getRow(32).getCell(letraValorHatchCover).value =  items[0].HatchCoverTwinBoxJson[i].Import;
      worksheet1.getRow(33).getCell(letraValorHatchCover).value =  items[0].HatchCoverTwinBoxJson[i].Export;
      worksheet1.getRow(34).getCell(letraValorHatchCover).value =  items[0].HatchCoverTwinBoxJson[i].RestowLine;
      letraValorHatchCover++; 
    }
  }

    /*6) LOAD */
  if(items[0].LoadJson != ''){ 
    let l=0; 
    let longitudLoad =  items[0].LoadJson.length;

    if(items[0].LoadJson[0].LoadPortJson != null){

      for (let  i = 0; i < longitudLoad; i++) { 
        let longitudLoadPort = items[0].LoadJson[i].LoadPortJson.length;
        
        //***************load port****************//
        let posX=0; 
        let pos_code=4;
        for (let  j = 0; j < longitudLoadPort; j++) {  
          //incremento horizontal 
          let posX_FULL=4+posX;
          let posX_EMPTY=5+posX;
          let posX_TRANS=6+posX;

          //incremento vertical 
          let posY_C20=57+l;
          let posY_C20RF=58+l;
          let posY_C40=59+l;
          let posY_C40HC=60+l;
          let posY_C40RF=61+l;
          let posY_C45=62+l;
          
          worksheet1.getRow(55).getCell(pos_code).value =  items[0].LoadJson[i].LoadPortJson[j].LoadPortCode;

          //incremento  hacia abajo y a la derecha
          worksheet1.getRow(posY_C20).getCell(posX_FULL).value =  items[0].LoadJson[i].LoadPortJson[j].C20_FULL;
          worksheet1.getRow(posY_C20).getCell(posX_EMPTY).value =  items[0].LoadJson[i].LoadPortJson[j].C20_EMPTY;
          worksheet1.getRow(posY_C20).getCell(posX_TRANS).value =  items[0].LoadJson[i].LoadPortJson[j].C20_TRANS;

          worksheet1.getRow(posY_C20RF).getCell(posX_FULL).value =  items[0].LoadJson[i].LoadPortJson[j].C20RF_FULL;
          worksheet1.getRow(posY_C20RF).getCell(posX_EMPTY).value =  items[0].LoadJson[i].LoadPortJson[j].C20RF_EMPTY;
          worksheet1.getRow(posY_C20RF).getCell(posX_TRANS).value =  items[0].LoadJson[i].LoadPortJson[j].C20RF_TRANS;

          worksheet1.getRow(posY_C40).getCell(posX_FULL).value =  items[0].LoadJson[i].LoadPortJson[j].C40_FULL;
          worksheet1.getRow(posY_C40).getCell(posX_EMPTY).value =  items[0].LoadJson[i].LoadPortJson[j].C40_EMPTY;
          worksheet1.getRow(posY_C40).getCell(posX_TRANS).value =  items[0].LoadJson[i].LoadPortJson[j].C40_TRANS;

          worksheet1.getRow(posY_C40HC).getCell(posX_FULL).value =  items[0].LoadJson[i].LoadPortJson[j].C40HC_FULL;
          worksheet1.getRow(posY_C40HC).getCell(posX_EMPTY).value =  items[0].LoadJson[i].LoadPortJson[j].C40HC_EMPTY;
          worksheet1.getRow(posY_C40HC).getCell(posX_TRANS).value =  items[0].LoadJson[i].LoadPortJson[j].C40HC_TRANS;
        
          worksheet1.getRow(posY_C40RF).getCell(posX_FULL).value =  items[0].LoadJson[i].LoadPortJson[j].C40RF_FULL;
          worksheet1.getRow(posY_C40RF).getCell(posX_EMPTY).value =  items[0].LoadJson[i].LoadPortJson[j].C40RF_EMPTY;
          worksheet1.getRow(posY_C40RF).getCell(posX_TRANS).value =  items[0].LoadJson[i].LoadPortJson[j].C40RF_TRANS;
      
          worksheet1.getRow(posY_C45).getCell(posX_FULL).value =  items[0].LoadJson[i].LoadPortJson[j].C45_FULL;
          worksheet1.getRow(posY_C45).getCell(posX_EMPTY).value =  items[0].LoadJson[i].LoadPortJson[j].C45_EMPTY;
          worksheet1.getRow(posY_C45).getCell(posX_TRANS).value =  items[0].LoadJson[i].LoadPortJson[j].C45_TRANS;
        
          pos_code+=3;
          posX+=3;
        }
      
        //incremento vertical 
        let pos_C20=57+l;
        let pos_C20RF=58+l;
        let pos_C40=59+l;
        let pos_C40HC=60+l;
        let pos_C40RF=61+l;
        let pos_C45=62+l;

        worksheet1.getCell('AC'+pos_C20).value =  items[0].LoadJson[i].LoadTonJson[0].C20_FULL;
        worksheet1.getCell('AD'+pos_C20).value =  items[0].LoadJson[i].LoadTonJson[0].C20_EMPTY;

        worksheet1.getCell('AC'+pos_C20RF).value =  items[0].LoadJson[i].LoadTonJson[0].C20RF_FULL;
        worksheet1.getCell('AD'+pos_C20RF).value =  items[0].LoadJson[i].LoadTonJson[0].C20RF_EMPTY;

        worksheet1.getCell('AC'+pos_C40).value =  items[0].LoadJson[i].LoadTonJson[0].C40_FULL;
        worksheet1.getCell('AD'+pos_C40).value =  items[0].LoadJson[i].LoadTonJson[0].C40_EMPTY;
    
        worksheet1.getCell('AC'+pos_C40HC).value =  items[0].LoadJson[i].LoadTonJson[0].C40HC_FULL;
        worksheet1.getCell('AD'+pos_C40HC).value =  items[0].LoadJson[i].LoadTonJson[0].C40HC_EMPTY;
      
        worksheet1.getCell('AC'+pos_C40RF).value =  items[0].LoadJson[i].LoadTonJson[0].C40RF_FULL;
        worksheet1.getCell('AD'+pos_C40RF).value =  items[0].LoadJson[i].LoadTonJson[0].C40RF_EMPTY;
    
        worksheet1.getCell('AC'+pos_C45).value =  items[0].LoadJson[i].LoadTonJson[0].C45_FULL;
        worksheet1.getCell('AD'+pos_C45).value =  items[0].LoadJson[i].LoadTonJson[0].C45_EMPTY;

        l=l+6;
      }
    }
  }

  /* PRODUCTIVIDAD */ 
  //Crane Operations 
  if(items[0].CraneOperationsJson != '' || items[0].CraneOperationsJson != null){
    let longitudCraneOperations =  items[0].CraneOperationsJson.length;
    let letraValor = 3;
      for (let  i = 0; i < longitudCraneOperations; i++) { 
        worksheet2.getRow(11).getCell(letraValor).value =  items[0].CraneOperationsJson[i].CraneAlias;

        if(items[0].CraneOperationsJson[i].DateBegin){
          worksheet2.getRow(12).getCell(letraValor).value = items[0].CraneOperationsJson[i].DateBegin ? DateFormater.formatDateTimeWithSlash( items[0].CraneOperationsJson[i].DateBegin) : '';       
        }else{
          worksheet2.getRow(12).getCell(letraValor).value = '';
        }       

        if(items[0].CraneOperationsJson[i].DateEnd){
          worksheet2.getRow(13).getCell(letraValor).value = items[0].CraneOperationsJson[i].DateEnd ? DateFormater.formatDateTimeWithSlash( items[0].CraneOperationsJson[i].DateEnd) : '';
        }else{
          worksheet2.getRow(13).getCell(letraValor).value = '';
        }

        
        letraValor++;
    }
  }
/*
  for (let  i = longitudCraneOperations; i < 6; i++) { 
    worksheet2.getRow(11).getCell(letraValor).value = '';
    letraValor++;
}
*/

  //Crane Gang Detentions
  if(items[0].CraneGangDetentionsJson != ''){
    let longitudCraneGangDetentions =  items[0].CraneGangDetentionsJson.length;
    for (let  i = 0; i < longitudCraneGangDetentions; i++) { 
      let letra='';
        if(i==0) {letra='C';}
        if(i==1) {letra='D';}
        if(i==2) {letra='E';}
        if(i==3) {letra='F';}
        if(i==4) {letra='G';}
        if(i==5) {letra='H';}

      worksheet2.getCell(letra+24).value =  items[0].CraneGangDetentionsJson[i].CraneAlias;

      let longitudGangDetentions=  items[0].CraneGangDetentionsJson[i].GangDetentionsJson.length;

      let end=longitudGangDetentions*3;
      for (let j=0; j<end;) { 
        let m=25+j; 
        worksheet2.getCell(letra+m).value = items[0].CraneGangDetentionsJson[i].TransactionBegin ? DateFormater.formatDateTimeWithSlash( items[0].CraneGangDetentionsJson[i].TransactionBegin) : '';
        j=j+3;
      }
      for (let j=0; j<end;) { 
        let m=26+j; 
        worksheet2.getCell(letra+m).value = items[0].CraneGangDetentionsJson[i].TransactionFinish ? DateFormater.formatDateTimeWithSlash( items[0].CraneGangDetentionsJson[i].TransactionFinish) : '';
        j=j+3;
      }
    }
  }
  
  var columna=3;

  //Terminal Delays
  if(items[0].TerminalDelaysJson != ''){
    let longitudTerminalDelays =  items[0].TerminalDelaysJson.length;
    columna=3;
    for (let  i = 0; i < longitudTerminalDelays; i++) { //es por grua
    let longitudDelays=  items[0].TerminalDelaysJson[i].DelaysJson.length;
    let letra=38; 
    for (let j=0; j<longitudDelays; j++) { //tiempos perdidos de esa grua
      // if(items[0].TerminalDelaysJson[i].DelaysJson[j].TotalTime){
          worksheet2.getRow(letra).getCell(1).value =  items[0].TerminalDelaysJson[i].DelaysJson[j].TdrCode ? items[0].TerminalDelaysJson[i].DelaysJson[j].TdrCode : '';
          worksheet2.getRow(letra).getCell(2).value =  items[0].TerminalDelaysJson[i].DelaysJson[j].DelayDsEn ? items[0].TerminalDelaysJson[i].DelaysJson[j].DelayDsEn : ''; 
          worksheet2.getRow(letra).getCell(columna).value =  items[0].TerminalDelaysJson[i].DelaysJson[j].TotalTime ? items[0].TerminalDelaysJson[i].DelaysJson[j].TotalTime : 0;
        //}
        letra++;
      }
      columna++; 
    }
  }

  //Vessel Line Delays
  if(items[0].VesselLineDelaysJson != ''){
    let longitudVesselLineDelays =  items[0].VesselLineDelaysJson.length;
    columna=3;
    for (let  i = 0; i < longitudVesselLineDelays; i++) { //es por grua
      let longitudDelays=  items[0].VesselLineDelaysJson[i].DelaysJson.length;
      let letra=58;
      
      for (let j=0; j<longitudDelays; j++) { //tiempos perdidos de esa grua
        //if( items[0].VesselLineDelaysJson[i].DelaysJson[j].TotalTime !== '' ){
          worksheet2.getRow(letra).getCell(1).value =  items[0].VesselLineDelaysJson[i].DelaysJson[j].TdrCode ? items[0].VesselLineDelaysJson[i].DelaysJson[j].TdrCode : '';
          worksheet2.getRow(letra).getCell(2).value =  items[0].VesselLineDelaysJson[i].DelaysJson[j].DelayDsEn ? items[0].VesselLineDelaysJson[i].DelaysJson[j].DelayDsEn : '';
          worksheet2.getRow(letra).getCell(columna).value =  items[0].VesselLineDelaysJson[i].DelaysJson[j].TotalTime ? items[0].VesselLineDelaysJson[i].DelaysJson[j].TotalTime : 0;
        //}
        letra++;
      }
      columna++; 
    }
  }
  //Port Delays
  if(items[0].PortDelaysJson != ''){
    let longitudPortDelays =  items[0].PortDelaysJson.length;
    columna=3;
    for (let  i = 0; i < longitudPortDelays; i++) { //es por grua
      let longitudDelays=  items[0].PortDelaysJson[i].DelaysJson.length;
      let letra=73; 
      for (let j=0; j<longitudDelays; j++) { //tiempos perdidos de esa grua
        //if( items[0].PortDelaysJson[i].DelaysJson[j].TotalTime !== '' ){
          worksheet2.getRow(letra).getCell(1).value =  items[0].PortDelaysJson[i].DelaysJson[j].TdrCode ? items[0].PortDelaysJson[i].DelaysJson[j].TdrCode : '';
          worksheet2.getRow(letra).getCell(2).value =  items[0].PortDelaysJson[i].DelaysJson[j].DelayDsEn ? items[0].PortDelaysJson[i].DelaysJson[j].DelayDsEn : '';     
          worksheet2.getRow(letra).getCell(columna).value = items[0].PortDelaysJson[i].DelaysJson[j].TotalTime ? items[0].PortDelaysJson[i].DelaysJson[j].TotalTime : 0;
        //}
        letra++;
      }
      columna++; 
    }
  }

  /* RESTOW */
  if(items[0].RestowListJson != ''){ 
    let longitudRestowList =  items[0].RestowListJson.length;

    for (let  i = 0; i < longitudRestowList; i++) { 
      let j=i+8;  
    
      worksheet3.getCell('B'+j).value =  items[0].RestowListJson[i].ContainerCode;
      worksheet3.getCell('C'+j).value =  items[0].RestowListJson[i].Opr;
      worksheet3.getCell('D'+j).value =  items[0].RestowListJson[i].IsoCode;
      worksheet3.getCell('E'+j).value =  items[0].RestowListJson[i].Status;     
      worksheet3.getCell('F'+j).value =  items[0].RestowListJson[i].B_B;
      worksheet3.getCell('G'+j).value =  items[0].RestowListJson[i].B_S_B;  
      worksheet3.getCell('H'+j).value =  items[0].RestowListJson[i].POL;
      worksheet3.getCell('I'+j).value =  items[0].RestowListJson[i].POD;
      worksheet3.getCell('J'+j).value =  items[0].RestowListJson[i].PreviousPosition;    
      worksheet3.getCell('K'+j).value =  items[0].RestowListJson[i].NewPosition;
      worksheet3.getCell('L'+j).value =  items[0].RestowListJson[i].RestowAccount;
      worksheet3.getCell('M'+j).value = items[0].RestowListJson[i].ContainerRemark ? items[0].RestowListJson[i].ContainerRemark : ''; 
    }
  }

  /* IMO LIST*/ 
  if(items[0].ImoListJson != ''){
    let longitudImoList =  items[0].ImoListJson.length;

    for (let  i = 0; i < longitudImoList; i++) { 
      let j=i+8;  
      worksheet4.getCell('B'+j).value =  items[0].ImoListJson[i].ContainerCode;
      worksheet4.getCell('C'+j).value =  items[0].ImoListJson[i].Opr;
      worksheet4.getCell('D'+j).value =  items[0].ImoListJson[i].Categ;
      worksheet4.getCell('E'+j).value =  items[0].ImoListJson[i].Status;    
      worksheet4.getCell('F'+j).value =  items[0].ImoListJson[i].IsoCode;
      worksheet4.getCell('G'+j).value =  items[0].ImoListJson[i].GrossWeigth;    
      worksheet4.getCell('H'+j).value =  items[0].ImoListJson[i].ImdgClassAbbrev;
      worksheet4.getCell('I'+j).value =  items[0].ImoListJson[i].UnNumber;
      worksheet4.getCell('J'+j).value =  items[0].ImoListJson[i].POD;    
      worksheet4.getCell('K'+j).value =  items[0].ImoListJson[i].StowagePosition;
      worksheet4.getCell('M'+j).value = items[0].ImoListJson[i].ContainerRemark ? items[0].ImoListJson[i].ContainerRemark : '';
    }
  } 

  /* IMO SUMARY*/ 
  //DISCHARGE
  let longitudIIIJsonDischarge =  items[0].ImoSummaryJson[0].IIIJson.length;
  for (let  i = 0; i < longitudIIIJsonDischarge; i++) { 
    let j=i+8; 
    worksheet4.getCell('U'+j).value =  items[0].ImoSummaryJson[0].IIIJson[i].SummaryValue;
  }

  let longitudIIJsonDischarge =  items[0].ImoSummaryJson[0].IIJson.length;
  for (let  i = 0; i < longitudIIJsonDischarge; i++) { 
    let j=i+8; 
    worksheet4.getCell('S'+j).value =  items[0].ImoSummaryJson[0].IIJson[i].SummaryValue;
  }

  let longitudIJsonDischarge =  items[0].ImoSummaryJson[0].IJson.length;
  for (let  i = 0; i < longitudIJsonDischarge; i++) { 
    let j=i+8; 
    worksheet4.getCell('Q'+j).value =  items[0].ImoSummaryJson[0].IJson[i].SummaryValue;
  }


  //LOAD
  let longitudIIIJsonLoad =  items[0].ImoSummaryJson[1].IIIJson.length;
  for (let  i = 0; i < longitudIIIJsonLoad; i++) { 
    let j=i+14; 
    worksheet4.getCell('U'+j).value =  items[0].ImoSummaryJson[1].IIIJson[i].SummaryValue;
  }

  let longitudIIJsonLoad =  items[0].ImoSummaryJson[1].IIJson.length;
  for (let  i = 0; i < longitudIIJsonLoad; i++) { 
    let j=i+14; 
    worksheet4.getCell('S'+j).value =  items[0].ImoSummaryJson[1].IIJson[i].SummaryValue;
  }

  let longitudIJsonLoad =  items[0].ImoSummaryJson[1].IJson.length;
  for (let  i = 0; i < longitudIJsonLoad; i++) { 
    let j=i+14; 
    worksheet4.getCell('Q'+j).value =  items[0].ImoSummaryJson[1].IJson[i].SummaryValue;
  }

  //RELAY
  let longitudIIIJsonRelay =  items[0].ImoSummaryJson[2].IIIJson.length;
  for (let  i = 0; i < longitudIIIJsonRelay; i++) { 
    let j=i+20; 
    worksheet4.getCell('U'+j).value =  items[0].ImoSummaryJson[2].IIIJson[i].SummaryValue;
  }

  let longitudIIJsonRelay =  items[0].ImoSummaryJson[2].IIJson.length;
  for (let  i = 0; i < longitudIIJsonRelay; i++) { 
    let j=i+20; 
    worksheet4.getCell('S'+j).value =  items[0].ImoSummaryJson[2].IIJson[i].SummaryValue;
  }

  let longitudIJsonRelay =  items[0].ImoSummaryJson[2].IJson.length;
  for (let  i = 0; i < longitudIJsonRelay; i++) { 
    let j=i+20; 
    worksheet4.getCell('Q'+j).value =  items[0].ImoSummaryJson[2].IJson[i].SummaryValue;
  }

  /* OOG */ 
  if(items[0].OogListJson != ''){  
    let longitudOogList =  items[0].OogListJson.length;
  
    for (let  i = 0; i < longitudOogList; i++) { 
      let j=i+8;  
      worksheet5.getCell('B'+j).value =  items[0].OogListJson[i].ContainerCode;
      worksheet5.getCell('C'+j).value =  items[0].OogListJson[i].Opr;
      worksheet5.getCell('D'+j).value =  items[0].OogListJson[i].IsoCode;
      worksheet5.getCell('E'+j).value =  items[0].OogListJson[i].GrossWeigth;    
      worksheet5.getCell('F'+j).value =  items[0].OogListJson[i].POL;
      worksheet5.getCell('G'+j).value =  items[0].OogListJson[i].POD;    
      worksheet5.getCell('H'+j).value =  items[0].OogListJson[i].StowagePosition;
      worksheet5.getCell('I'+j).value =  items[0].OogListJson[i].LeftSide;
      worksheet5.getCell('J'+j).value =  items[0].OogListJson[i].RightSide;    
      worksheet5.getCell('K'+j).value =  items[0].OogListJson[i].OverHeight;
      worksheet5.getCell('L'+j).value = items[0].OogListJson[i].ContainerRemark ? items[0].OogListJson[i].ContainerRemark : ''; 
    }
  }

  /* other terminals */ 
  if(items[0].OthersTerminalListJson != ''){
    let longitudOthersTerminalList =  items[0].OthersTerminalListJson.length;
  
    for (let  i = 0; i < longitudOthersTerminalList; i++) { 
      let j=i+8;  
      worksheet6.getCell('B'+j).value =  items[0].OthersTerminalListJson[i].Opr;
      worksheet6.getCell('C'+j).value =  items[0].OthersTerminalListJson[i].Categ;
      worksheet6.getCell('D'+j).value =  items[0].OthersTerminalListJson[i].POD;
      worksheet6.getCell('E'+j).value =  items[0].OthersTerminalListJson[i].POL;    
      worksheet6.getCell('F'+j).value =  items[0].OthersTerminalListJson[i].Status;
      worksheet6.getCell('G'+j).value =  items[0].OthersTerminalListJson[i].IsoCode;    
      worksheet6.getCell('H'+j).value =  items[0].OthersTerminalListJson[i].ContainerCode;
      worksheet6.getCell('I'+j).value =  items[0].OthersTerminalListJson[i].Tons;
      worksheet6.getCell('J'+j).value =  items[0].OthersTerminalListJson[i].ImoCode;    
      worksheet6.getCell('K'+j).value =  items[0].OthersTerminalListJson[i].Temp;
      worksheet6.getCell('L'+j).value =  items[0].OthersTerminalListJson[i].TerminalName;
      worksheet6.getCell('M'+j).value =  items[0].OthersTerminalListJson[i].ContainerRemark ? items[0].OthersTerminalListJson[i].ContainerRemark : '';
  
    }
  }

//Turns Monday Friday
  let longitudTurnsMondayFriday =  items[0].TurnsMondayFridayJson.length;
  let xMondayFriday=0; 
  for (let  i = 0; i < longitudTurnsMondayFriday; i++) { 
    if(items[0].TurnsMondayFridayJson[i].MovsJson != null){ 
      let longitudMovsMondayFriday = items[0].TurnsMondayFridayJson[i].MovsJson.length;

      for (let  j = 0; j < longitudMovsMondayFriday; j++) {   
        //incremento horizontal 

        let posX_dFull20=2+xMondayFriday;
        let posX_dFull40=3+xMondayFriday;
        let posX_dEmpty20=4+xMondayFriday;
        let posX_dEmpty40=5+xMondayFriday;

        let posX_lFull20=6+xMondayFriday;
        let posX_lFull40=7+xMondayFriday;
        let posX_lEmpty20=8+xMondayFriday;
        let posX_lEmpty40=9+xMondayFriday;


        //incremento vertical
        let y_C20=19+j;

        //incremento  hacia abajo y a la derecha
        worksheet8.getRow(y_C20).getCell(posX_dEmpty20).value =  items[0].TurnsMondayFridayJson[i].MovsJson[j].DischargedEmpty20;
        worksheet8.getRow(y_C20).getCell(posX_dEmpty40).value =  items[0].TurnsMondayFridayJson[i].MovsJson[j].DischargedEmpty40;
        worksheet8.getRow(y_C20).getCell(posX_dFull20).value =  items[0].TurnsMondayFridayJson[i].MovsJson[j].DischargedFull20;
        worksheet8.getRow(y_C20).getCell(posX_dFull40).value =  items[0].TurnsMondayFridayJson[i].MovsJson[j].DischargedFull40;

        worksheet8.getRow(y_C20).getCell(posX_lEmpty20).value =  items[0].TurnsMondayFridayJson[i].MovsJson[j].LoadedEmpty20;
        worksheet8.getRow(y_C20).getCell(posX_lEmpty40).value =  items[0].TurnsMondayFridayJson[i].MovsJson[j].LoadedEmpty40;
        worksheet8.getRow(y_C20).getCell(posX_lFull20).value =  items[0].TurnsMondayFridayJson[i].MovsJson[j].LoadedFull20;
        worksheet8.getRow(y_C20).getCell(posX_lFull40).value =  items[0].TurnsMondayFridayJson[i].MovsJson[j].LoadedFull40;
      } 
      xMondayFriday+=8;
    }
  }

//Turns Sunday Json
  let longitudTurnsSundayJson =  items[0].TurnsSundayJson.length;
  let xSunday=0; 
  for (let  i = 0; i < longitudTurnsSundayJson; i++) { 
    if(items[0].TurnsSundayJson[i].MovsJson != null){ 
      let longitudMovsSunday = items[0].TurnsSundayJson[i].MovsJson.length;
      
      for (let  j = 0; j < longitudMovsSunday; j++) {   
        //incremento horizontal 
        let posX_dFull20=2+xSunday;
        let posX_dFull40=3+xSunday;
        let posX_dEmpty20=4+xSunday;
        let posX_dEmpty40=5+xSunday;

        let posX_lFull20=6+xSunday;
        let posX_lFull40=7+xSunday;
        let posX_lEmpty20=8+xSunday;
        let posX_lEmpty40=9+xSunday;
        //incremento vertical
        let y_C20=39+j;

        //incremento  hacia abajo y a la derecha
        worksheet8.getRow(y_C20).getCell(posX_dEmpty20).value =  items[0].TurnsSundayJson[i].MovsJson[j].DischargedEmpty20;
        worksheet8.getRow(y_C20).getCell(posX_dEmpty40).value =  items[0].TurnsSundayJson[i].MovsJson[j].DischargedEmpty40;
        worksheet8.getRow(y_C20).getCell(posX_dFull20).value =  items[0].TurnsSundayJson[i].MovsJson[j].DischargedFull20;
        worksheet8.getRow(y_C20).getCell(posX_dFull40).value =  items[0].TurnsSundayJson[i].MovsJson[j].DischargedFull40;

        worksheet8.getRow(y_C20).getCell(posX_lEmpty20).value =  items[0].TurnsSundayJson[i].MovsJson[j].LoadedEmpty20;
        worksheet8.getRow(y_C20).getCell(posX_lEmpty40).value =  items[0].TurnsSundayJson[i].MovsJson[j].LoadedEmpty40;
        worksheet8.getRow(y_C20).getCell(posX_lFull20).value =  items[0].TurnsSundayJson[i].MovsJson[j].LoadedFull20;
        worksheet8.getRow(y_C20).getCell(posX_lFull40).value =  items[0].TurnsSundayJson[i].MovsJson[j].LoadedFull40;
      } 
      xSunday+=8;
    }  
  }

  //Turns Saturday
  let longitudTurnsSaturday =  items[0].TurnsSaturdayJson.length;
  let xSaturday=0; 
  for (let  i = 0; i < longitudTurnsSaturday; i++) { 
    if(items[0].TurnsSaturdayJson[i].MovsJson != null){ 
      let longitudMovsSaturday = items[0].TurnsSaturdayJson[i].MovsJson.length;

      for (let  j = 0; j < longitudMovsSaturday; j++) {   
        //incremento horizontal 

        let posX_dFull20=28+xSaturday;
        let posX_dFull40=29+xSaturday;
        let posX_dEmpty20=30+xSaturday;
        let posX_dEmpty40=31+xSaturday;


        let posX_lFull20=32+xSaturday;
        let posX_lFull40=33+xSaturday;
        let posX_lEmpty20=34+xSaturday;
        let posX_lEmpty40=35+xSaturday;
        //incremento vertical
        let y_C20=19+j;

        //incremento  hacia abajo y a la derecha
        worksheet8.getRow(y_C20).getCell(posX_dEmpty20).value =  items[0].TurnsSaturdayJson[i].MovsJson[j].DischargedEmpty20;
        worksheet8.getRow(y_C20).getCell(posX_dEmpty40).value =  items[0].TurnsSaturdayJson[i].MovsJson[j].DischargedEmpty40;
        worksheet8.getRow(y_C20).getCell(posX_dFull20).value =  items[0].TurnsSaturdayJson[i].MovsJson[j].DischargedFull20;
        worksheet8.getRow(y_C20).getCell(posX_dFull40).value =  items[0].TurnsSaturdayJson[i].MovsJson[j].DischargedFull40;

        worksheet8.getRow(y_C20).getCell(posX_lEmpty20).value =  items[0].TurnsSaturdayJson[i].MovsJson[j].LoadedEmpty20;
        worksheet8.getRow(y_C20).getCell(posX_lEmpty40).value =  items[0].TurnsSaturdayJson[i].MovsJson[j].LoadedEmpty40;
        worksheet8.getRow(y_C20).getCell(posX_lFull20).value =  items[0].TurnsSaturdayJson[i].MovsJson[j].LoadedFull20;
        worksheet8.getRow(y_C20).getCell(posX_lFull40).value =  items[0].TurnsSaturdayJson[i].MovsJson[j].LoadedFull40;
      } 
      xSaturday+=8;
    }
  }

  //Turns Holiday
  let longitudTurnsHoliday =  items[0].TurnsHolidayJson.length;
  let xHoliday=0; 
  for (let  i = 0; i < longitudTurnsHoliday; i++) { 
    if(items[0].TurnsHolidayJson[i].MovsJson != null){ 
      let longitudMovsHoliday = items[0].TurnsHolidayJson[i].MovsJson.length;

      for (let  j = 0; j < longitudMovsHoliday; j++) {   
        //incremento horizontal 

        let posX_dFull20=28+xHoliday;
        let posX_dFull40=29+xHoliday;
        let posX_dEmpty20=30+xHoliday;
        let posX_dEmpty40=31+xHoliday;


        let posX_lFull20=32+xHoliday;
        let posX_lFull40=33+xHoliday;
        let posX_lEmpty20=34+xHoliday;
        let posX_lEmpty40=35+xHoliday;

        //incremento vertical
        let y_C20=39+j;
        //incremento  hacia abajo y a la derecha
        worksheet8.getRow(y_C20).getCell(posX_dEmpty20).value =  items[0].TurnsHolidayJson[i].MovsJson[j].DischargedEmpty20;
        worksheet8.getRow(y_C20).getCell(posX_dEmpty40).value =  items[0].TurnsHolidayJson[i].MovsJson[j].DischargedEmpty40;
        worksheet8.getRow(y_C20).getCell(posX_dFull20).value =  items[0].TurnsHolidayJson[i].MovsJson[j].DischargedFull20;
        worksheet8.getRow(y_C20).getCell(posX_dFull40).value =  items[0].TurnsHolidayJson[i].MovsJson[j].DischargedFull40;

        worksheet8.getRow(y_C20).getCell(posX_lEmpty20).value =  items[0].TurnsHolidayJson[i].MovsJson[j].LoadedEmpty20;
        worksheet8.getRow(y_C20).getCell(posX_lEmpty40).value =  items[0].TurnsHolidayJson[i].MovsJson[j].LoadedEmpty40;
        worksheet8.getRow(y_C20).getCell(posX_lFull20).value =  items[0].TurnsHolidayJson[i].MovsJson[j].LoadedFull20;
        worksheet8.getRow(y_C20).getCell(posX_lFull40).value =  items[0].TurnsHolidayJson[i].MovsJson[j].LoadedFull40;
      } 
      xHoliday+=8;
    }
  }

//exporta el excel
  let fileName = documento;
  if(value==71)
    fileName = documento+' '+this.$t('label.discountedTdr');

  workbook.xlsx.writeBuffer()
    .then(buffer => {
      const file = new File([buffer], `${fileName}.xlsx`, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
      let formData = new FormData();
        formData.append('file1', file);
        this.$http.ejecutar('POST', 'Tdr-file', formData)
        .then((response) => {
          this.dataTdr=response.data.data;
          
          let VisitTdrJson = {
            VisitId: this.$store.state.visitas.VisitId,
            TdrRoute: this.dataTdr.files[0].path,
          };

          this.$http.post("VisitTdr-insert", VisitTdrJson, { root: 'VisitTdrJson' })
            .then((response) => {
              
            }).catch((err) => {
              this.loadingOverlay = false;
              this.$notify({
                  group: 'container',
                  title: '¡Error!',
                  text: err,
                  type: "error"
              });
          });
           
          saveAs(new Blob([buffer]), `${fileName}.xlsx`)
            this.loadingOverlay = false;
            this.$notify({
              group: 'container',
              text: this.$t('label.reportSuccessfully'),
              type: "success"
            });
        }).catch((err) => {
          this.loadingOverlay = false;
          console.log('error 2', err);
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        });
    }) 
          /*const url = URL.createObjectURL(new Blob([res.data], {
              type: 'application/vnd.ms-excel'
          }))*/

         // this.loadingOverlay = false;
      })
      .catch((error)=> {
        this.loadingOverlay = false;
        console.log("FAIL 2: ", error);
      })
      //this.content = transformSheets(sheets)
  /*}).catch( err =>{
      console.log("FAIL: ", err);
  })*/    
}

function data() {
  return {
    //items: [],
    dataTdr: [],
    loadingOverlay: false,
  };
}
//methods
  async function getDataTdr (value, items) {
    if(items.length > 0)
    await this.getTdrExcel(value, items);
  } 

export default {
    data,
    methods: {
      getTdrExcel,
      getDataTdr,
    },
    computed:{
      ...mapState({
          user: state => state.auth.user,
          VisitId: state => state.visitas.VisitId,
          itinerarySelected: state => state.visitas.itinerarySelected,
          dropItemReport: state => state.visitas.dropItemReport,
      }),
    },
  }