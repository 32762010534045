<template>
    <div>
        <CCollapse :show="activeItem(1)">
            <appTerminalDetails :loading="apiStateLoading"/>
        </CCollapse>
        <CCollapse :show="activeItem(2)">
            <appGruas :loading="apiStateLoading"/>
        </CCollapse>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import appTerminalDetails from './terminal_details/index';
    import appGruas from './gruas/app-gruas';
    //methods
    function activeItem(item) {
        return this.dropItemAgenda === item;
    }
    //computed
    function idIndentification(){
        return this.VisitId == '';
    }
    //watch
    function tabIndex(newValue,OldValue){
        if(OldValue === 2){
            this.$store.state.visitas.dropItemAgenda = 0;
        }
    }
    export default {
        name:"agenda",
        props:['apiStateLoading'],
        methods:{
            activeItem,
        },
        components:{
            appTerminalDetails,
            appGruas
        },
        computed:{
            idIndentification,
            ...mapState({
                VisitId: state => state.visitas.VisitId,
                tabIndex: state => state.visitas.tabIndex,
                dropItemAgenda: state => state.visitas.dropItemAgenda
            })
        },
        watch:{
            tabIndex
        }
    }
</script>